import React from 'react'
import {ProductPage , ProductContainer , ProductHeader,SwipeContent,SlideText,SlideImage} from './ProductSectionEllements'

import {Models } from './Data'
import {LibraryModel} from '../../Library/LibraryModel'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation,Autoplay, Pagination, Scrollbar ,EffectCoverflow} from 'swiper';
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import "swiper/components/effect-coverflow/effect-coverflow.min.css"

import './ProductStyle.css'


import Cookies from 'universal-cookie';


const cookies = new Cookies();

cookies.get('language') ? console.log('Selected language is ',cookies.get('language'))  : cookies.set('language', 'ru', { path: '/' })



const ListModel = Models.map((Models) =>

<SwiperSlide key={Models.image}>
    <SwipeContent to={Models.link}>
        <SlideImage src={Models.image} />
        <SlideText>{LibraryModel[cookies.get('language')]['headerText'+Models.id]}</SlideText>
    </SwipeContent>
</SwiperSlide>
)


SwiperCore.use([EffectCoverflow,Autoplay,Navigation,Pagination, Scrollbar]);

const ProductSection = () => {
    return (
        <ProductPage id="product">
            <ProductContainer>
                <ProductHeader>
                      {LibraryModel[cookies.get('language')].lineup}
                </ProductHeader>

                <Swiper effect={'coverflow'} 
                        grabCursor={true} 
                        centeredSlides={true} 
                        slidesPerView={'auto'} 
                        loop={true} 
                        navigation
                        autoplay={{
                            "delay": 4000,
                            "disableOnInteraction": true
                          }} 
                        coverflowEffect={{"rotate": 30, "stretch": 0, "depth": 0,  "modifier": 1,  "slideShadows": false}} 
                        pagination={true} 
                        className="mySwiper">
                    
                    {ListModel}
                    
                </Swiper>
            </ProductContainer>
        </ProductPage>
    )
}

export default ProductSection
