export const NewsLibraryText ={
    news1:{
        ru:{
            title:'С 17 по 20 марта, компания «VIPROPAT» SRL принимала участие в XIV-ой Международной специализированной выставке энергосберегающих технологий, систем отопления, газоснабжения, кондиционирования воздуха «MOLDENERGY-2010», которая проходила в Международном выставочном центре «Moldexpo».',
            text1:'На выставке наша компания представила продукцию компании Jenbacher, а именно – когенерационные установки, которые служат для генерации тепла и электроэнергии. Данные установки работают на любом газе(природный газ, биогаз, коксовый газ, древесный газ и т.д.). ',
            text2:'Технология, качество и сервис',
            text3:'Около 50 лет австрийская компания-производитель INNIO является мировым лидером в области разработки и производства газопоршневых двигателей для эффективной выработки электрической и тепловой энергии. Двигатели Jenbacher в диапазоне мощностей от 0,25 до 3 МВт предназначены для длительной работы в стационарных условиях и отличаются исключительно высоким коэффициентом полезного действия, малой степенью токсичности отработанных газов, большим сроком службы и высокой надежностью.',
            text4:'Экономичность',
            text5:'Наша преданность делу и выполнение обязательств по постоянному развитию выпускаемой продукции увеличивают ресурс всех компонентов двигателя, даже при работе на загрязненных газах, например, на газах из органических отходов;',
            text6:'Долговечность',
            text7:'Большой срок службы до капитального ремонта, удобная в обслуживании конструкция двигателя и низкое потребление топлива обеспечивает максимальную экономичность;',
            text8:'Надежность',
            text9:'Усовершенствованные и оптимально сконструированные системы контроля и мониторинга обеспечивают идеальное плановое обслуживание, максимально безопасную работу и высочайшую надежность в эксплуатации.',
            text10:'Служба Jenbacher разрабатывает сервисные соглашения под индивидуальные потребности заказчиков. Сервисные центры компании Jenbacher, стратегически расположены более чем в 30 странах мира и предлагают прекрасную сервисную поддержку продукции, включая всестороннее обучение персонала заказчиков, которое осуществляется штатом высококлассных специалистов.',

        },

        md:{
            title:'În perioada 17-20 martie, compania „VIPROPAT” SRL a participat la cea de-a XIV-a expoziție internațională specializată de tehnologii de economisire a energiei, sisteme de încălzire, alimentare cu gaz, aer condiționat „MOLDENERGY-2010”, care a avut loc la Centrul Internațional de Expoziții „Moldexpo”.',
            text1:'La expoziție, compania noastră a prezentat produsele companiei Jenbacher, și anume, unitățile de cogenerare, care sunt utilizate pentru a genera căldură și electricitate. Aceste centrale funcționează pe orice gaz (gaz natural, biogaz, gaz pentru cocserie, gaz pentru lemn etc.).',
            text2:'Tehnologie, calitate și servicii',
            text3:'De aproximativ 50 de ani, compania austriacă de producție INNIO este lider mondial în dezvoltarea și producția de motoare cu piston pe gaz pentru generarea eficientă de energie electrică și căldură. Motoarele Jenbacher cu o putere cuprinsă între 0,25 și 3 MW sunt proiectate pentru funcționare continuă în condiții staționare și se disting prin eficiență extrem de ridicată, toxicitate redusă a gazelor de eșapament, durată lungă de viață și fiabilitate ridicată.',
            text4:'Rentabilitate',
            text5:'Dedicarea și angajamentul nostru pentru dezvoltarea continuă a produsului extinde durata de viață a tuturor componentelor motorului, chiar și atunci când funcționează pe gaze contaminate, cum ar fi gazele reziduale organice;',
            text6:'Durabilitate',
            text7:'Durata lungă de viață înainte de revizie, designul motorului ușor de întreținut și consumul redus de combustibil asigură o eficiență maximă;',
            text8:'Fiabilitate',
            text9:'Sistemele de control și monitorizare avansate și proiectate optim asigură o întreținere planificată ideală, o funcționare sigură maximă și o fiabilitate operațională maximă.',
            text10:'Jenbacher dezvoltă acorduri de servicii adaptate nevoilor individuale ale clienților. Centrele de servicii Jenbacher sunt situate strategic în peste 30 de țări din întreaga lume și oferă asistență excelentă pentru produse, inclusiv o pregătire extinsă a clienților de către un personal înalt calificat.',

        },

        en:{
            title:'From 17 to 20 March, the company "VIPROPAT" SRL took part in the XIV-th International specialized exhibition of energy-saving technologies, heating systems, gas supply, air conditioning "MOLDENERGY-2010", which was held at the International Exhibition Center "Moldexpo".',
            text1:'At the exhibition, our company presented the products of the Jenbacher company, namely, cogeneration units, which are used to generate heat and electricity. These plants operate on any gas (natural gas, biogas, coke oven gas, wood gas, etc.).',
            text2:'Technology, quality and service',
            text3:'For about 50 years, the Austrian manufacturing company INNIO has been a world leader in the development and production of gas piston engines for the efficient generation of electricity and heat. Jenbacher engines in the power range from 0.25 to 3 MW are designed for continuous operation in stationary conditions and are distinguished by extremely high efficiency, low exhaust gas toxicity, long service life and high reliability.',
            text4:'Profitability',
            text5:'Our dedication and commitment to continuous product development extends the life of all engine components, even when operating on contaminated gases such as organic waste gases;',
            text6:'Durability',
            text7:'Long service life before overhaul, service-friendly engine design and low fuel consumption ensure maximum efficiency;',
            text8:'Reliability',
            text9:'Advanced and optimally designed control and monitoring systems provide ideal scheduled maintenance, maximum safe operation and the highest operational reliability.',
            text10:'Jenbacher develops service agreements tailored to the individual needs of customers. Jenbacher`s service centers are strategically located in more than 30 countries worldwide and offer excellent product support, including extensive customer training by a highly qualified staff.',

        },
    },



    news2:{
        ru:{
            title:'INNIO завершает отделение от General Electric и превращается в независимую энергетическую Компанию',
            text1:'ПРЕСС РЕЛИЗ',
            text2:'INNIO завершает отделение от General Electric и превращается в независимую энергетическую Компанию',
            text3:'INNIO объявляет, что Advent International завершила приобретение бизнеса GE Distributed Power',
            text4:'INNIO является самой молодой энергетической компанией в мире с более чем 80-летним опытом работы с поршневыми двигателями в области распределительной генерации электроэнергии и сжатия газа',
            text5:'Два мощных товарных бренда - газовые двигатели Jenbacher и Waukesha - сохранят свою фирменную идентичность под руководством INNIO',
            text6:'ЙЕНБАХ, Австрия-6 ноября 2018-INNIO сегодня объявляет, что Advent International завершила приобретение бизнеса GE Distributed Power и теперь является независимой энергетической компанией, переименованной в INNIO. Сделка включает в себя линейки продуктов Jenbacher и Waukesha, цифровую платформу и предложения сопутствующих услуг с основными операционными площадками в Австрии, Канаде и Соединенных Штатах.',
            text7:'"Это необычайное время, чтобы стать независимой энергетической компанией. С продолжающимся ростом возобновляемых источников энергии по всему миру и повышенным вниманием к энергоэффективности INNIO имеет хорошие позиции, чтобы быть ключевым фактором и неотъемлемой частью преобразования энергии”,- сказал Карлос Ланге, президент и генеральный директор INNIO.',
            text8:'Сегодня INNIO празднует свое новое имя и официальный запуск, становясь самой молодой энергетической компанией в мире, поскольку она получает два мощных бренда — линейку газовых двигателей Jenbacher и Waukesha. INNIO имеет доказанный послужной список более чем 80-летнего наследия поршневых двигателей в генерации электроэнергии и сжатия газа.',
            text9:'Поскольку разворачивается глобальный энергетический переход, INNIO, как ведущий поставщик решений, стратегически позиционирует вести определяющую роль в энергетической отрасли.',
            text10:'” Очень приятно быть рядом с INNIO, поскольку он становится независимой энергетической компанией",-сказал Раньян Сен, управляющий партнер Advent International. "Бренды и технологии Jenbacher и Waukesha признаны во всем мире за их производительность, надежность и технологическое совершенство. Мы ожидаем дальнейшего устойчивого роста технологий производства электроэнергии и газа в глобальном масштабе.”',
        },

        md:{
            title:'INNIO finalizează separarea de General Electric și devine o companie independentă de energie',
            text1:'COMUNICAT DE PRESA',
            text2:'INNIO finalizează separarea de General Electric și devine o companie independentă de energie',
            text3:'INNIO anunță Advent International finalizează achiziția de afaceri distribuite de energie GE',
            text4:'INNIO este cea mai tânără companie energetică din lume cu peste 80 de ani de experiență în generarea alternativă de energie și compresia gazelor',
            text5:'Două mărci puternice de produse - motoarele pe gaz Jenbacher și Waukesha - își vor păstra identitatea corporativă sub conducerea INNIO',
            text6:'JENBACH, Austria - 6 noiembrie 2018 - INNIO anunță astăzi că Advent International și-a finalizat achiziția de afaceri GE Distributed Power și este acum o companie independentă de energie redenumită INNIO. Acordul include liniile de produse Jenbacher și Waukesha, platforma digitală și oferte de servicii conexe cu operațiuni majore în Austria, Canada și Statele Unite.',
            text7:'"Este un moment extraordinar pentru a deveni o companie energetică independentă. Odată cu creșterea continuă a surselor regenerabile la nivel mondial și cu un accent sporit pe eficiența energetică, INNIO este bine poziționat pentru a fi un factor cheie și o parte integrantă a conversiei energiei", a declarat Carlos Lange, președinte și CEO INNIO.',
            text8:'Astăzi INNIO își sărbătorește noul nume și lansarea oficială, devenind cea mai tânără companie energetică din lume, în timp ce câștigă două mărci puternice - linia de motoare pe gaz Jenbacher și Waukesha. INNIO are o experiență dovedită de peste 80 de ani de moștenire alternativă a motorului în generarea de energie și compresia gazului.',
            text9:'Pe măsură ce se desfășoară tranziția energetică globală, INNIO, în calitate de furnizor de soluții lider, este poziționat strategic pentru a conduce un rol definitoriu în industria energetică.',
            text10:'„Este minunat să fii în preajma INNIO pentru că devine o companie independentă de energie”, a spus Ranyan Sen, Managing Partner la Advent International. „Brandurile și tehnologiile Jenbacher și Waukesha sunt recunoscute la nivel mondial pentru performanța, fiabilitatea și excelența tehnologică. Așteptăm cu nerăbdare o creștere durabilă a tehnologiilor de generare a energiei și a gazelor la nivel global. ”',
        },

        en:{
            title:'INNIO completes separation from General Electric and becomes an independent energy company',
            text1:'PRESS RELEASE',
            text2:'INNIO completes separation from General Electric and becomes an independent energy company',
            text3:'INNIO Announces Advent International Completes Acquisition of GE Distributed Power Business',
            text4:'INNIO is the youngest energy company in the world with over 80 years of experience in reciprocating power generation and gas compression',
            text5:'Two powerful product brands - Jenbacher and Waukesha gas engines - will retain their corporate identity under the leadership of INNIO',
            text6:'JENBACH, Austria - November 6, 2018 - INNIO today announces that Advent International has completed its acquisition of the GE Distributed Power business and is now an independent energy company renamed INNIO. The deal includes the Jenbacher and Waukesha product lines, digital platform and related services offerings with major operations in Austria, Canada and the United States.',
            text7:'“This is an extraordinary time to become an independent energy company. With the continued growth of renewables worldwide and an increased focus on energy efficiency, INNIO is well positioned to be a key factor and integral part of energy conversion,” said Carlos Lange, President and CEO INNIO.',
            text8:'Today INNIO celebrates its new name and official launch, becoming the youngest energy company in the world as it gains two powerful brands - the Jenbacher and Waukesha gas engine range. INNIO has a proven track record of over 80 years of reciprocating engine heritage in power generation and gas compression.',
            text9:'As the global energy transition unfolds, INNIO, as a leading solution provider, is strategically positioned to lead a defining role in the energy industry.',
            text10:'“It\'s great to be around INNIO as it becomes an independent energy company," said Ranyan Sen, Managing Partner at Advent International. "Jenbacher and Waukesha\'s brands and technologies are recognized worldwide for their performance, reliability and technological excellence. We expect further sustainable growth in power and gas generation technologies on a global scale. ”',
        },
    },

    news3:{
        ru:{
            title:'В Бендерах состоялся запуск когенерационной установки ГУП «Биохим»',
            text1:'1 сентября 2013',
            text2:'На территории Бендерского биохимического завода произведен запуск когенерационной установки. В мероприятии приняли участие Председатель Правительства ПМР Татьяна Туранская, Заместитель Председателя Правительства ПМР Виталий Улитка, Глава Государственной администрации города Бендеры Юрий Гервазюк, а также руководство предприятий ГУП “Биохим” и SC “Vipropat” SRL.',
            text3:'Работа по строительству станции была начата в 2007 году, но завершить все работы удалось только в нынешнем году после передачи предприятия в госсобственность. Сегодня после завершения всех пусконаладочных работ, был произведен запуск станции.',
            text4:'Татьяна Туранская, разрезав символическую ленту, поздравила всех с открытием установки и отметила, что начало работы станции позволит получать дешевую электроэнергию, в том числе для уличного освещения, и обеспечит Бендеры горячей водой.',
            text5:'В свою очередь, главный инженер SC “Vipropat” SRL Николай Будько подчеркнул, что установка потребляет природный газ и является экологически чистой.',
            text6:'По его словам, тепловая энергия будет полностью востребована во время отопительного сезона. Также предусмотрена работа станции и в случае аварийных ситуаций на городских теплопунктах. «Установки смогут обеспечить бесперебойное электроснабжение таких важных объектов, как Бендерский хлебокомбинат, роддом и городская больница».',

        },

        md:{
            title:'La Bender a avut loc lansarea instalației de cogenerare la Întreprinderea unitară de stat "Biochim"',
            text1:'1 Septembrie 2013',
            text2:'Pe teritoriul uzinei biochimice Bender a fost efectuată lansarea instalației de cogenerare. La eveniment a participat Președintele Guvernului din Transnistria Tatiana Turanskaia, Vice-Președinte al Guvernului Transnistrian, Vitalie Ulitca, Șeful administrației de Stat a orașului Bender, Iurie Ghervaziuc, precum și conducerea întreprinderilor IUS "Biochim" și SC "Vipropat" SRL.',
            text3:'ucrările de construcție a stației au fost inițiate în anul 2007, însă toate lucrările au fost finalizate abia în anul curent, după ce întreprinderea a fost transferată în proprietate publică. Astăzi, după finalizarea tuturor lucrărilor de lansare, a fost lansată stația.',
            text4:'Tatiana Turanskaia, tăind panglica simbolică, i-a felicitat pe toți cu deschiderea instalației și a menționat că începerea lucrărilor stației va permite obținerea energiei electrice ieftine, inclusiv pentru iluminatul stradal, și va asigura Bender-ul cu apă caldă.',
            text5:'La rîndul său, inginerul-șef al SC "Vipropat" SRL, Nicolae Budico, a subliniat că instalația consumă gaze naturale și este ecologică.',
            text6:'Potrivit lui, energia termică va fi pe deplin solicitată în timpul sezonului de încălzire. De asemenea, este prevăzută funcționarea stației și în cazul situațiilor de urgență la stațiile termice urbane. "Instalațiile vor putea asigura alimentarea neîntreruptă a instalațiilor importante, cum ar fi combinatul de pâine Bender, maternitatea și Spitalul Municipal".',

        },

        en:{
            title:'The launch of the cogeneration plant at the Unitary State Enterprise "Biochim" took place in Bender',
            text1:'September 1 2013',
            text2:'On the territory of the Bender biochemical plant, the launch of the cogeneration plant was carried out. The event was attended by the president of the Transnistrian Government Tatiana Turanskaya, Vice-President of the Transnistrian Government Vitalie Ulitca, head of the State Administration of the city of Bender Iurie Ghervaziuc, as well as the management of enterprises IUS "Biochim" and SC "Vipropat" SRL.',
            text3:'Construction work on the station was started in 2007, but all work was completed only this year, after the company was transferred to public ownership. Today, after the completion of all the launch work, the station was launched.',
            text4:'Tatiana Turanskaia, cutting the symbolic ribbon, congratulated everyone with the opening of the plant and noted that the start of work on the station will allow obtaining cheap electricity, including for street lighting, and provide the Bender with hot water.',
            text5:'For his part, the chief engineer of SC "Vipropat" SRL, Nicolae Budico, stressed that the plant consumes natural gas and is environmentally friendly.',
            text6:'According to him, heat energy will be fully in demand during the heating season. It is also provided for the operation of the station in case of emergency situations at Urban thermal stations. "The facilities will be able to ensure the uninterrupted supply of important buildings such as the Bender bread combine, the Maternity Hospital and the Municipal Hospital."',

        },
    }
}