import styled from 'styled-components'

export const VacancyBlock = styled.div`
    background: #000 url('img/background.png') no-repeat;
    background-position: center;
    background-size: cover;
    width:100%;
    height:100%;
    min-height:100vh;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
`

export const VacancyLayer = styled.div`
    width:100%;
    height:100%;
    min-height:100vh;
    background: rgba(0 , 0 , 0 , 0.4);
    
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

`

export const VacancyHeadText = styled.span`
    font-size:4rem;
    font-weight:bold;
    text-shadow: 0px 0px 20px black;
    color: white;
    text-align:center;
    
`