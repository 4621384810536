export const ProjectText ={
    1:{
        ru:{
            title:'Республика Молдова, район Хынчешть, поселок Фырлэдень.',
            text1:'Электростанция собственных нужд на базе контейнерного модуля JМC 320',
            text2:'Технические характеристики',
            text3:'Тип газового двигателя J 320 GS-D25 B.N-L',
            text4:'Мощность электрическая установленная: 1 067 Kw',
            text5:'Напряжение на клеммах генератора: 400В',
            text6:'Расход природного газа при 100% нагрузки: 272 Nm3/h',
            text7:'Теплотворная способность природного газа: 9,5 Kwh/Nm3',
            text8:'Расход Биогаза при 100% нагрузки: 580 Nm3/h',
            text9:'Теплотворная способность Биогаза: 4,5 Kwh/Nm3',
            text10:'Расход смазочного масла: 0,33 kg/h',
            text11:'Попутная тепловая мощность 1 159 Kw',
            text12:'Данная электростанция собственных нужд предназначена для снабжения электроэнергией электрической сети местного спиртового завода и фермерского хозяйства. Электростанция может работать как в параллель с главной электрической сетью, так и в автономном режиме.',
            text13:'Попутное тепло вырабатываемое электростанцией полезно используется как на подогрев питательной воды паровых котлов, так и на обогрев метантанков биогазовой станции.',
            text14:'Электростанция на базе контейнерного модуля JМC 320 сдана в эксплуатацию 16 февраля 2017 года и успешно работает в настоящее время.',
            text15:'На представленных ниже фотографиях показаны фазы строительства, внешний и внутренний вид станции зимой 2016- 2017 года.',
        },

        md:{
            title:'Republica Moldova, raionul Hîncești, satul Fîrlădeni.',
            text1:'Centrala electrică a nevoilor proprii pe baza modulului containerizat JMC 320',
            text2:'Specificații tehnice',
            text3:'Tipul motorului cu gaz J 320 GS-D25 B.N-L',
            text4:'Putere electrică instalată: 1 067 Kw',
            text5:'Tensiune la bornele generatorului: 400В',
            text6:'Consumul de gaze naturale la 100% sarcină: 272 Nm3/h',
            text7:'Capacitatea calorică a gazelor naturale: 9,5 Kwh/Nm3',
            text8:'Consumul de biogaz la 100% sarcină: 580 Nm3/h',
            text9:'Capacitatea calorică a biogazului: 4,5 Kwh/Nm3',
            text10:'Consumul de ulei de lubrifiere: 0,33 kg/h',
            text11:'Capacitate termică adiacentă 1 159 Kw',
            text12:'Această centrală electrică a nevoilor proprii este destinată furnizării de energie electrică a rețelei electrice a unei fabrici locale de alcool și a unei ferme. Centrala electrică poate funcționa atât în paralel cu rețeaua electrică principală, cât și offline.',
            text13:'Căldura generată de centralele electrice este utilizată atât pentru încălzirea apei nutritive a cazanelor de abur, cât și pentru încălzirea metantanelor stației de biogaz.',
            text14:'Centrala electrică bazată pe modulul de containere JMC 320 a fost dată în exploatare la 16 februarie 2017 și funcționează cu succes în prezent.',
            text15:'Fotografiile de mai jos prezintă fazele de construcție, aspectul exterior și interior al stației în timpul iernii 2016-2017.',
        },

        en:{
            title:'Republic Of Moldova, Hincesti district, Firladeni village.',
            text1:'Power plant of own needs on the basis of the containerized module JMC 320',
            text2:'Technical specifications',
            text3:'Type of gas engine J 320 GS-D25 B.N-L',
            text4:'Installed electrical power: 1 067 Kw',
            text5:'Voltage at Generator terminals: 400В',
            text6:'Natural gas consumption at 100% load: 272 Nm3/h',
            text7:'Calorific capacity of natural gas: 9,5 Kwh/Nm3',
            text8:'Biogas consumption at 100% load: 580 Nm3/h',
            text9:'The calorific capacity of biogas: 4,5 Kwh/Nm3',
            text10:'Lubricating oil consumption: 0,33 kg/h',
            text11:'Adjacent thermal capacity 1 159 Kw',
            text12:'This power plant of its own needs is intended for the supply of electricity to the electrical network of a local alcohol factory and a farm. The power plant can operate both in parallel with the main power grid and island mode.',
            text13:'The heat generated by power plant is used both for heating the nutrient water of steam boilers, and for heating the biogas station metatancs.',
            text14:'The power plant based on the container module JMC 320 was put into operation on 16 February 2017 and is currently operating successfully.',
            text15:'The photos below show the phases of construction, the exterior and interior appearance of the station in the winter of 2016-2017.',
        },
    },

    2:{
        ru:{
            title:'г. Тирасполь, Молдавия',
            text1:'Тепло-электро станция на базе двух контейнерных модулей JMC 420',
            text2:'Технические характеристики',
            text3:'Мощность электрическая(тепловая): 2 800 Kw (2,4Gkal/h)',
            text4:'Расход природного газа при 100% нагрузки: 694 Nm3/h',
            text5:'Теплотворная способность природного газа: 9,5 Kwh/Nm3',
            text6:'Расход смазочного масла: 0,88 kg/h',
            text7:'Коэффициент полезного действия электрический: 42,9%',
            text8:'Коэффициент полезного действия тепловой: 43,1%',
            text9:'Коэффициент полезного действия общий: 86%',
            text10:'Данная теплоэлектростанция предназначена, как для снабжения электроэнергией городской электрической сети, так и для круглогодичного снабжения горящей водой жилых районов города через централизованную систему горячего водоснабжения.',
            text11:'Тепло-электро станция на базе двух контейнерных модулей JMC 420 сдана в эксплуатацию в марте 2010 года и успешно работает в настоящее время.',
            text12:'На представленных ниже фотографиях показаны завершающие фазы строительства , а так же внешний вид станции летом 2012 года.',
            text13:'2010 год – строительство тепло-электростанции / 2012 год – тепло-электростанции находится в эксплуатации.',
        },

        md:{
            title:'Republica Moldova, or. Tiraspol',
            text1:'Stație termo-electrică pe baza a două module containerizate JMC 420',
            text2:'Specificații tehnice',
            text3:'Puterea electrică (termică): 2 800 Kw (2,4Gkal/h)',
            text4:'Consumul de gaze naturale la 100% sarcină: 694 Nm3/h',
            text5:'Capacitatea calorică a gazelor naturale: 9,5 Kwh/Nm3',
            text6:'Consumul de ulei de lubrifiere: 0,88 kg/h',
            text7:'Factorul de utilitate electric: 42,9%',
            text8:'Coeficientul de utilitate termică utilă: 43,1%',
            text9:'Rata de utilitate utilă totală: 86%',
            text10:'Această stație termică este destinată atât pentru alimentarea cu energie electrică a rețelei electrice urbane, cât și pentru aprovizionarea cu apă caldă pe tot parcursul anului a zonelor rezidențiale ale orașului prin intermediul unui sistem centralizat de alimentare cu apă caldă.',
            text11:'Stația termo-electrică bazată pe două module de containere JMC 420 a fost dată în exploatare în martie 2010 și funcționează cu succes în prezent.',
            text12:'Fotografiile de mai jos prezintă fazele finale ale construcției , precum și aspectul stației în vara anului 2012.',
            text13:'2010 - construirea unei centrale termice. / 2012 - centrala termică este în funcțiune.',
        },

        en:{
            title:'Republic Of Moldova, Tiraspol city',
            text1:'Thermo-electric station based on two containerized modules JMC 420',
            text2:'Technical specifications',
            text3:'Electrical power(thermalpower): 2 800 Kw (2,4Gkal/h)',
            text4:'Natural gas consumption at 100% load: 694 Nm3/h',
            text5:'Calorific capacity of natural gas: 9,5 Kwh/Nm3',
            text6:'Lubricating oil consumption: 0,88 kg/h',
            text7:'Electric utility factor: 42,9%',
            text8:'Coefficient of useful thermal utility: 43,1%',
            text9:'Total useful utility rate: 86%',
            text10:'This thermal station is intended both for the supply of electricity to the urban electricity grid, and for the year-round supply of hot water to residential areas of the city through a centralized hot water supply system.',
            text11:'The thermo-electric station based on two container modules JMC 420 was commissioned in March 2010 and is currently operating successfully.',
            text12:'The photos below show the final stages of construction , as well as the appearance of the station in the summer of 2012.',
            text13:'year 2010 – construction of a thermal power plant. / year 2012 - thermal power plants are in operation.',
        },
    },

    3:{
        ru:{
            title:'г.Бендеры. Молдавия',
            text1:'Тепло-электро станции на базе двух модулей JMS 420',
            text2:'Данная теплоэлектростанция предназначена, как для снабжения электроэнергией городской электрической сети, так и для круглогодичного снабжения горящей водой жилых районов города через централизованную систему горячего водоснабжения.',
            text3:'Теплоэлектростанция в данный момент находится в завершающей стадии строительства.',
            text4:'Выполнены пуско-наладочные работы с выводом модулей на проектную электрическую мощность 1400КВт',
           },

           md:{
            title:'Republica Moldova, or. Benderi',
            text1:'Stație termo-electrică pe baza a două module JMS 420',
            text2:'Această stație termică este destinată atât pentru alimentarea cu energie electrică a rețelei electrice urbane, cât și pentru aprovizionarea cu apă caldă pe tot parcursul anului a zonelor rezidențiale ale orașului prin intermediul unui sistem centralizat de alimentare cu apă caldă.',
            text3:'Centrala termică este în prezent în faza finală de construcție.',
            text4:'Lucrări de pornire și reglare cu ieșirea modulelor la puterea electrică de proiect de 1400kw',
           },

           en:{
            title:'Republic of Moldova, Bender city',
            text1:'Thermo-electric station based on two modules JMS 420',
            text2:'This thermal station is intended both for the supply of electricity to the urban electricity grid, and for the year-round supply of hot water to residential areas of the city through a centralized hot water supply system.',
            text3:'The thermal power plant is currently in the final phase of construction.',
            text4:'Start-up and adjustment work with the module output to the project electrical power of 1400kw',
           },
    },

    4:{
        ru:{
            title:'г.Губкинский , Ямало-Ненецкий автономный округ',
            text1:'Электростанция собственных нужд на базе двух контейнерных модулей JGC 416',
            text2:'Технические характеристики',
            text3:'Мощность электрическая установленная: 2 360 Kw',
            text4:'Напряжение на клеммах генераторов: 6 300В',
            text5:'Расход природного газа при 100% нагрузки: 580 Nm3/h',
            text6:'Теплотворная способность природного газа: 9,5 Kwh/Nm3',
            text7:'Расход смазочного масла: 0,74 kg/h',
            text8:'Коэффициент полезного действия электрический: 43,0%',
            text9:'Данная электростанция собственных нужд предназначена для снабжения электроэнергией локальной электрической сети газового промысла.',
            text10:'Электростанция на базе двух контейнерных модулей JGC416 сдана в эксплуатацию в феврале 2015 года и успешно работает в настоящее время.',
            text11:'На представленных ниже фотографиях показаны фазы строительства , внешний и внутренний вид станции зимой 2014- 2015 года.',
            
        },


        md:{
            title:'Gubkinsky, raionul autonom Yamalo-Nenets',
            text1:'Centrală auxiliară bazată pe două module de containere JGC 416',
            text2:'Specificații',
            text3:'Putere electrică instalată: 2 360 Kw',
            text4:'Tensiunea terminalului generatorului: 6 300V',
            text5:'Consum de gaze naturale la 100% sarcină: 580 Nm3 / h',
            text6:'Valoarea calorică a gazelor naturale: 9,5 Kwh / Nm3',
            text7:'Consumul de ulei lubrifiant: 0,74 kg / h',
            text8:'Eficiență electrică: 43,0%',
            text9:'Această centrală electrică pentru nevoi proprii este proiectată pentru a furniza energie electrică rețelei electrice locale a câmpului de gaz.',
            text10:'Centrala electrică bazată pe două module de containere JGC416 a fost pusă în funcțiune în februarie 2015 și funcționează cu succes în prezent.',
            text11:'Fotografiile de mai jos prezintă fazele de construcție, vederi externe și interne ale stației în iarna 2014-2015.',
            
        },


        en:{
            title:'Gubkinskii city, Yamalo-Nenets Autonomous District',
            text1:'Plant Power Station on the basis of two container modules JGC 416',
            text2:'Performance Information:',
            text3:'Rated electric power: 2 360 kW',
            text4:'Generator terminal voltage: 6 300 V',
            text5:'Natural gas consumption at 100% load: 580 Nm3/h',
            text6:'Natural gas calorific value: 9,5 kWh/Nm3',
            text7:'Lubrication oil consumption: 0,74 kg/h',
            text8:'Electric efficiency: 43,0%',
            text9:'The Plant Power Station is designed to supply power a local electric net of a gas field.',
            text10:'The Power Station on the basis of two container modules JGC 416 was commissioned in February 2015 and has been successfully working up to now.',
            text11:'The pictures presented below show various stages of construction, outside and inside view of the station in the period of winter 2014-2015.',
            
        },
    },

}