import styled from 'styled-components'



export const BlockWithMotord = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    background:#EFEFEF;
`

export const BlockMotorsContainer = styled.div`
    width:100%;
    max-width:1100px;
    padding:35px 0;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    @media screen and (max-width : 960px){
        padding:20px 0;
      }
`

export const BlockMotorsHead = styled.div`
    width:100%;
    font-size:2rem;
    font-weight:bold;
    text-align:center;
    margin-bottom:15px;
    
    @media screen and (max-width : 960px){
      
    font-size:32px;
    
    font-size:1.3rem;
    margin-bottom:20px;
    }
`

export const BlockMotorsDescriptions = styled.div`
    width:50%;
    display:flex;
    padding:20px;
    justify-content:center;
    align-items:center;
    font-weight:500;
    font-size:1.2rem;
    
    @media screen and (max-width : 960px){
        width:100%;
    }
`

export const BlockMotorsImages = styled.div`
    width:35%;
    display:flex;
    
    justify-content:center;
    padding:50px;
    align-items:center;

    @media screen and (max-width : 960px){
        width:90%;
    }
`

export const Image = styled.img`
     width:100%;
    object-fit: cover;
    box-shadow:5px 5px 20px 5px black;
    border-radius:5px;
`

export const BlockMotorsText = styled.div`
    width:90%;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:20px;
    font-weight:500;
    font-size:1.1rem;
    text-align:center;
    @media screen and (max-width: 960px ){
        padding:5px;
    }
`