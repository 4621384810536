import React from 'react'
import { AboutContainer, AboutHeader, AboutPage, AboutText } from './AboutSectionEllements'
import Cookies from 'universal-cookie';
import {MainPageText} from '../../Library/LibraryMainPage'

const cookies = new Cookies();

const AboutSection = () => {
    return (
        <AboutPage id="about">
            <AboutContainer>
                    <AboutHeader>
                        {MainPageText[cookies.get('language')].secondTitle}
                    </AboutHeader>
                    <AboutText>
                    <b>{MainPageText[cookies.get('language')].secondP1}</b> {MainPageText[cookies.get('language')].secondT1}
                    </AboutText>
                    <AboutText>
                    <b>{MainPageText[cookies.get('language')].secondP2}</b> {MainPageText[cookies.get('language')].secondT2}
                    </AboutText>
            </AboutContainer>
        </AboutPage>
    )
}

export default AboutSection
