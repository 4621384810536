import React  from 'react'
import FirstBlock from '../components/SeriaModel/FirstBlock'
import SecondBlock from '../components/SeriaModel/SecondBlock'
import ThirdBlock from '../components/SeriaModel/ThirdBlock'
import FourBlock from '../components/SeriaModel/FourBlock'
const model6 = () => {
  
  
    window.scrollTo(0, 0);
    return (
        <>  
            <FirstBlock ellement={6} />
            <SecondBlock ellement={6} />

            <ThirdBlock ellement={6} record={1} modif={true}/>
            <ThirdBlock ellement={6} record={2} modif={false}/>
            <ThirdBlock ellement={6} record={3} modif={true}/>
            <ThirdBlock ellement={6} record={4} modif={false}/> 
            
             <FourBlock ellement={6}/>
        </>
    )
}

export default model6
