export const MotorsPrewiev = {
    ru:{
        secondBlockTextTitle2:'Постоянное совершенствование в течение 40 лет.',
        secondBlockText2:'Двигатели Jenbacher второго модельного ряда, введённые в производство в 1976 году, имеют высокий КПД в диапозонемощности от 250 кВт до 350 кВт. Надёжная кострукция и концепция стационарного двигателя обеспечивают долговечность его деталей и ресурс в 60 000 часов до первого капитальгого ремонта. Улучшенные компоненты и проверенная система управления и контроля дополнительно повышают надежность этих двигателей.',
        
        secondBlockTextTitle3:'ЭКОНОМИЧНОЧТЬ, ДОЛГОВЕЧНОСТЬ, НАДЁЖНОСТЬ.',
        secondBlockText3:'Длительные интервалы обслуживания, удобный дизайн двигателя и низкий расход топлива обеспечивают максимальную эффективность в наших двигателях третьего модельного ряда. Улучшенные компоненты продлевают срок службы даже при использовании особых газов, таких как газ мусорных свалок. Двигатели третьего модельного ряда обеспечивают выдающийся интервал обслуживания вплоть до 80 000 часов работы до капитального ремонта. Этот тип двигателя выделяется в своем диапазоне мощности от 400 до 1100 кВт благодаря своей технической зрелости и высокой степени надежности. ',
        
        secondBlockTextTitle4:'Этап повышения эффективности.',
        secondBlockText4:'Современные двигатели 4 модельного ряда мощностью от 800 до 1500 кВт, основанные на проверенных конструктивных решениях моделей 3 и 6, характеризуются высокой мощности и выдающимся КПД. Улучшенный контроль и мониторинг обеспечивают простоту технического обслуживания, высокую надежность и доступность.',
        
        secondBlockTextTitle6:'Современная передовая технология.',
        secondBlockText6:'На основе нашего богатого опыта, конструкция двигателей Jenbacher 6 модельного ряда постоянно совершенствуется и данные двигатели представляют собой надёжное и современное оборудование в диапазоне мощности от 2 до 4,5 МВт. Частота вращения двигателя 1500 об / мин обеспечивает высокую удельную мощности и низкие затраты на установку. Концепция форкамеры 6 модельного ряда обеспечивает высокую эффективность КПД при низкой токсичности выбросов. Проверенная конструкция и усовершенствованные компоненты обеспечивают срок службы в 60 000 часов работы до первого капитального ремонта. Модель j624 оснащается новой технологией 2-ступенчатого турбонаддува, которая обеспечивает высокий электрический КПД в сочетании с гибкой адаптацией к условиям окружающей среды.',
        

        secondBlockTextTitlediane:'Система управления двигателем DIA.NE.',
        secondBlockTextdiane:'DIA.NE XT4* – новое поколение системы управления и визуализации для газовых двигателей INNIO. Система состоит из блоков входа/выхода, прочного промышленного компьютера (контроллера) и 15- дюймового сенсорного экрана, встроенного в локальный шкаф управления. Для дистанционного управления предусмотрена программа «DIA.NE XT4 HMI Client», подходящая для установки на любой компьютер (в том числе на планшет с Windows 8®).',
        


        seria2:{
            record1:{
                head:'Биогазовая установка Шлиттерс, Австрия',
                fuel:'Биогаз',
                engine:'1 x J208',
                power:'350kW',
                thermalPower:'370kW',
                expluatation:'2008, 2015(1)',
                text:'Один двигатель J208 на комбинированной теплоэлектроцентрали (ТЭЦ) в Шлиттерсе ежегодно преобразует около 12 000 тонн остатков пищевых и биологических отходов в электричество и тепло. Остаточные переваренные биоотходы затем прессуются в компост или превращаются в жидкий навоз для удобрения сельскохозяйственных полей в регионе. (1) двигатель заменен на новый J208'
            },
            record2:{
                head:'Станция по переработке сточных вод Фритценс, Австрия',
                fuel:'Газ сточных вод',
                engine:'1 x J208',
                power:'660kW',
                thermalPower:'761kW',
                expluatation:'2002(1),2005(2)',
                text:'На заводе по очистке сточных вод во Фритценсе два двигателя J208, работающие на канализационном газе, вырабатывают более 3,3 МВтч электроэнергии для компенсации годовой потребности объекта в электроэнергии. Кроме того, тепло, вырабатываемое двигателями, используется для переработки пищевых отходов и выработки дополнительной энергии, которая приносит пользу работе завода по обращению с отходами. (1) первый двигатель (2) второй двигатель'
            },
            record3:{
                head:'Станция по переработке сточных вод Штрас-им-Циллерталь, Австрия',
                fuel:'Газ сточных вод',
                engine:'1 x J208 ,1 x J312',
                power:'625 kW',
                thermalPower:'724 kW ',
                expluatation:'2009',
                text:'Два газовых двигателя Jenbacher производят 120% электроэнергии и тепла, необходимых на станции очистки сточных вод в Штрассе. Избыточная мощность подается в локальную сеть.'
            },
            record4:{
                head:'J208 & J320 Arif Habib Group Business Building в Карачи , Пакистан',
                fuel:'Газ сточных вод',
                engine:'1xJ208 ,1xJ320',
                power:'330kW , 1.064kW',
                thermalPower:'',
                expluatation:'2009',
                text:'Для компании бизнес-конгломерата Arif Habib Group,базирующейся в Карачи, две генераторные установки Jenbacher генерируют электричество 1,394 кВт для поддержки своего головного офиса. Двигатель J208 уже достиг более 50 000 часов работы.'
            },
        },


        seria3:{
            record1:{
                head:'J312 & J320 Мусорная свалка * Дурбан, Южная Африка',
                fuel:'Свалочный газ',
                engine:'1xJ312, 1xJ320',
                power:'526 kW, 1.064 kW',
                thermalPower:'',
                expluatation:'2006',
                text:'На полигоне Дурбан две контейнерные генераторные установки Jenbacher с общей электрической мощностью 1590 кВт производят электроэнергию для местной муниципальной сети. Кроме того, использование свалочного газа для производства электроэнергии сдерживает загрязнение окружающей среды и проблемы со здоровьем, связанные с утечкойсвалочного газа.'
            },
            record2:{
                head:'J316 Комбинированная электростанция с охлаждением и теплоснабжением в больнице в Пекине Цинхэ, Китай',
                fuel:'Природный газ',
                engine:'2 x J316',
                power:'3,189 kW',
                thermalPower:'1,851 kW',
                expluatation:'2012',
                text:'В здании больницы Цинхэ были установлены два двигателя J316 с общей электрической мощностью 3189 кВт. С общей эффективностью больше чем 70%, блоки J316 улучшают энергоснабжение объекта а также обеспечивают теплом и горячей водой.'
            },
            record3:{
                head:'Буровая компания Ensign Jonah Field в Вайоминге, США',
                fuel:'Природный газ',
                engine:'24 x J320',
                power:'24,168 kW',
                thermalPower:'',
                expluatation:'2011',
                text:'В Юго-Западном Вайоминге крупный производитель газа развернул 24 двигателя J320 для восстановления буровой установки с использованием природного газа вместо дизельного топлива. В месторождении Jonah доступный газ на площадке позволяет производителю получить экономию затрат и снизить общие выбросы на площадке.'
            },
            record4:{
                head:'J320 Текстильные центры Amtex * Пенджаб, Пакистан',
                fuel:'Природный газ',
                engine:'12 x J320',
                power:'12,072 kW',
                thermalPower:'',
                expluatation:'2002, 2003, 2004, 2005, 2008',
                text:'Дюжина установок J320, работающих на природном газе, вырабатывают электроэнергию для прядильных фабрик в одном из важнейших текстильных центров Пакистана. Завод полагается на способности двигателей Jenbacher эффективно работать в трудной окружающей среде которая включает высокие температуры окружающей среды, пылевоздушный воздух на входе, и работа в островном режиме. часов работы.'
            },
        },
        
        
        seria4:{
            record1:{
                head:'J420 Больница Святого Барта в Лондоне, Великобритания',
                fuel:'Природный газ',
                engine:'1 x J420',
                power:'1,480 kW',
                thermalPower:'1,624 kW',
                expluatation:'2015',
                text:'Когенерационная установка мощностью 1,4 МВт включает абсорбционный охладитель мощностью 250 кВт, который подает охлаждающую воду в больницу. Двигатель J420 является краеугольным камнем нового энергетического центра, который обеспечил предприятию финансовую экономию за счет повышения его энергоэффективности, надежности и долговечности.'
            },
            record2:{
                head:'J420 Пиковая электростанция Эшфорд в графстве Кент, Великобритания',
                fuel:'Природный газ',
                engine:'14 x J420',
                power:'21 MW',
                thermalPower:'',
                expluatation:'2018',
                text:'Электрогенерирующая Пиковая станция в Ashford Power, Kings North Industrial Estate в графстве Кент работает на 14 двигателях Jenbacher J420 контейнерного типа. Когда двигатели этой полностью автоматизированной установки не работают, они находятся в режиме ожидания, готовы к вызову и запуску менее чем за 2 минуты.'
            },
            record3:{
                head:'J420 SV.CO теплицы Strijbisverbeek в Маасдижуке, Нидерланды',
                fuel:'Природный газ',
                engine:'1 x J420',
                power:'1,501 kW',
                thermalPower:'1,996 kW',
                expluatation:'2018',
                text:'Парник Strijbisverbeek в Maasdijuk, Нидерланды, полностью полагается на решении CHP парника на базе Jenbacher J420, полной системы выхлопных газов включая каталитический реактор для CO2 и полной шумо-изоляции. Энергия произведенная в этом парнике используется для освящения своих растении. Кроме того, они используют тепло ТЭЦ для обогрева своей теплицы в более холодные периоды и в ночное время.'
            },
            record4:{
                head:'J420 Биогазовые установки * Накорначасрима, Таиланд',
                fuel:'Биогаз',
                engine:'5 x J420',
                power:'7,105 kW',
                thermalPower:'',
                expluatation:'2012',
                text:'Завод Chok Yuen Yong получает прибыль от своих пять двигателей J420, которые обеспечивают надежную мощность на месте, а также снижают затраты на электроэнергию. Излишки произведенной электроэнергии поступают в общественную сеть.'
            },
        },
        
        
        seria6:{
            record1:{
                head:'J616 & J620 BMW в Регенсбурге и Лейпциге, Германия',
                fuel:'Природный газ',
                engine:'4xJ616,1xJ620',
                power:'10700 kW , 3000 kW',
                thermalPower:'9600 kW , 3120 kW',
                expluatation:'2011 , 2015',
                text:'Когенерационные установки, установленные на заводах BMW Group*s Регенсбурге и Лейпциге, могут генерировать энергию на месте, а также улавливать и использовать отработанное тепло двигателя для поддержки производственных процессов заводов. Зимнее отопление получается за счет сочетания отработанного тепла двигателей и тепла от существующих котлов.'
            },
            record2:{
                head:'J620 Кока-Кола Хелленик, Румыния',
                fuel:'Природный газ',
                engine:'2 x J620',
                power:'6,082 kW',
                thermalPower:'2,208 kW',
                expluatation:'2009',
                text:'С 2009 года два двигателя J620 снабжают Coca-Cola Hellenic Bottling Company энергией и теплом, а также горячей и охлажденной водой для ее работы. Благодаря удовлетворению потребностей объекта в электроэнергии, установленные двигатели помогают уменьшить углеродные выбросы компании и снизить ее общие эксплуатационные расходы.'
            },
            record3:{
                head:'J624 Hakha CES Дэчон, Южная Корея',
                fuel:'Природный газ',
                engine:'6 x J624',
                power:'25,182 kW',
                thermalPower:'25,350 kW',
                expluatation:'2014',
                text:'Имея в общей сложности шесть двигателей J624, работающих на природном газе, участок Hakha, Daejeon достигает в общей сложности 25 182 кВт электрической мощности при достижении общей эффективности 87%. С установкой этих двигателей Jenbacher этот участок стал одним из крупнейших газомоторных заводов в Южной Корее.'
            },
            record4:{
                head:'J624 2-ступенчатый турбонаддув Теплицы Serres Vinet * Машкуль, Франция',
                fuel:'Природный газ',
                engine:'2xJ624 ,2-stage turbocharged',
                power:'8,800 kW',
                thermalPower:'8,024 kW',
                expluatation:'2011',
                text:'На этом тепличном объекте два двухступенчатых турбированных газовых двигателя Jenbacher J624 позволяют французскому производителю Serres Vinet производить всю горячую воду и электричество, необходимые для его обширных тепличных операций с помидорами и салатом. Это первые 2-ступенчатые газовые двигатели с турбонаддувом во Франции и дают Serres Vinet гибкость для переключения между источниками энергии, либо обеспечить электрическую энергию, либо тепловую энергию, так как диктует рынок.'
            },
        },
        
    },


    md:{
        secondBlockTextTitle2:'Dezvoltarea continuă de peste 40 de ani.',
        secondBlockText2:'Introdus în 1976 și îmbunătățit continuu, motorul Jenbacher* model 2 oferă o eficiență extrem de ridicată în gama de puteri de 250 până la 350 kW. Designul său robust și conceptul de motor staționar au ca rezultat o durabilitate excelentă a componentelor și o durată de viață de 80.000 de ore de funcționare înainte de prima revizie majoră. Componentele îmbunătățite și un concept dovedit de control și monitorizare conferă acestui motor o fiabilitate remarcabilă.',
        
        secondBlockTextTitle3:'Eficient, durabil, fiabil',
        secondBlockText3:'Intervalele lungi de service, designul motorului ușor de întreținut și consumul redus de combustibil asigură o eficiență maximă în motoarele noastre Jenbacher de tip 3. Componentele îmbunătățite prelungesc durata de viață chiar și atunci când se utilizează gaze care nu sunt conducte, cum ar fi gazul de deșeuri. Motoarele noastre de tip 3 oferă un interval de service remarcabil cu până la 80.000 de ore de funcționare până la revizia majoră. Acest tip de motor se remarcă în gama de puteri de 400 până la 1100 kW datorită maturității sale tehnice și gradului ridicat de fiabilitate.',
        
        secondBlockTextTitle4:'O piatră de hotar a eficienței',
        secondBlockText4:'Bazându-se pe conceptele de proiectare dovedite ale tipurilor 3 și 6, motoarele moderne Jenbacher* de tip 4 din gama de puteri de 800 până la 1500 kW se caracterizează printr-o densitate mare de putere și o eficiență remarcabilă. Controlul și monitorizarea îmbunătățite asigură o întreținere preventivă ușoară, fiabilitate ridicată și disponibilitate.',
        
        secondBlockTextTitle6:'Tehnologie de ultimă oră',
        secondBlockText6:'Continuu rafinat bazat pe experienţa noastră vastă, motoarele Jenbacher * tip 6 sunt fiabile, produse avansate care deservesc gama de putere 2 la 4,5 MW. Turația motorului de 1.500 rpm asigură o densitate mare de putere și costuri reduse de instalare. Camera de pre-combustie de tip 6 permite o eficiență ridicată cu emisii reduse. Designul dovedit și componentele îmbunătățite susțin o durată de viață de 60.000 de ore de funcționare înainte de prima revizie majoră. Modelul J624 dispune de tehnologia avansată de turbocompresor în 2 trepte, care oferă o eficiență electrică ridicată, combinată cu o flexibilitate îmbunătățită într-o gamă largă de condiții ambientale.',
        
        
        secondBlockTextTitlediane:'Sistemul de control al motorului DIA.NE',
        secondBlockTextdiane:'DIA.NE XT4* – o nouă generație de sisteme de control și vizualizare pentru motoarele cu gaz INNIO. Sistemul este alcătuit din blocuri de intrare/ ieșire, un computer industrial robust (controler) și un ecran tactil de 15 inch încorporat într - un dulap de control local. Pentru controlul la distanță este prevăzut programul "DIA.NE XT4 HMI Client", potrivit pentru instalarea pe orice computer (inclusiv pe o tabletă cu Windows 8 ® ).',

        seria2:{
            record1:{
                head:'J208 Uzina de biogaz din Schlitters, Austria',
                fuel:'Biogaz',
                engine:'1 x J208',
                power:'350kW',
                thermalPower:'370kW',
                expluatation:'2008, 2015(1)',
                text:'Un singur motor J208 de la stația combinată de energie termică și electrică (CHP) din Schlitters transformă anual aproximativ 12.000 de tone de alimente și deșeuri biologice în electricitate și căldură. Deșeurile biologice reziduale digerate sunt apoi comprimate încompost sau transformate în gunoi de grajd lichid pentru a fertiliza terenurile agricole din regiune.(1) Motor înlocuit cu unul nou J208'
            },
            record2:{
                head:'J208 Stație de tratare a apelor uzate din Fritzens, Austria',
                fuel:'Gaz de canalizare',
                engine:'1 x J208',
                power:'660kW',
                thermalPower:'761kW',
                expluatation:'2002(1),2005(2)',
                text:'La stația de epurare a apelor uzate din Fritzens, două motoare J208 care funcționează cu gaz de epurare generează peste 3,3 MWh de energie electrică pentru a compensa cererea anuală de energie electrică a instalației. În plus, căldura generată de motoare este utilizată pentru a procesa deșeurile alimentare și pentru a genera energie suplimentară care aduce beneficii operațiunilor de gestionare a deșeurilorale instalației.(1) primul motor (2) al doilea motor'
            },
            record3:{
                head:'J208 Stație de tratare a apelor uzate în Strass im Zillertal, Austria',
                fuel:'Gaz de canalizare',
                engine:'1 x J208 ,1 x J312',
                power:'625 kW',
                thermalPower:'724 kW ',
                expluatation:'2009',
                text:'Două motoare cu gaz Jenbacher generează 120% din energia electrică și căldura necesară la stația de tratare a apelor uzate din Strass. Excesul de putere este alimentat în rețeaua locală.'
            },
            record4:{
                head:'J208 & J320 Arif Habib Group Business Building în Karachi, Pakistan',
                fuel:'Gaze naturale',
                engine:'1xJ208 ,1xJ320',
                power:'330kW , 1.064kW',
                thermalPower:'',
                expluatation:'2009',
                text:'Pentru Grupul Arif Habib, o companie conglomerat de afaceri cu sediul în Karachi, două grupuri electrogene Jenbacher generează 1.394 kW de energie electrică pentru a-și susține sediul central cu energie la fața locului. Motorul J208 a realizat deja mai mult de 50.000 de ore de funcționare.'
            },
        },

        seria3:{
            record1:{
                head:'J312 & J320 Depozitul de deșeuri în Durban, Africa de Sud',
                fuel:'Gaze de depozitare a deșeurilor',
                engine:'1xJ312, 1xJ320',
                power:'526 kW, 1.064 kW',
                thermalPower:'',
                expluatation:'2006',
                text:'La depozitul de deșeuri din Durban, două grupuri electrogene Jenbacher containerizate cu o putere electrică totală de 1.590 kW generează energie electrică pentru rețeaua locală municipală. În plus, utilizarea gazului de deșeuri pentru generarea de energie electrică reduce poluarea mediului și problemele de sănătate asociate cu gazul de deșeuri care se scurge.'
            },
            record2:{
                head:'J316 Combinare de răcire, căldură și centrală electrică la Spitalul din Beijing Qinghe, China',
                fuel:'Gaze Naurale',
                engine:'2 x J316',
                power:'3,189 kW',
                thermalPower:'1,851 kW',
                expluatation:'2012',
                text:'Clădirea și instalația Spitalului Qinghe au instalat două motoare J316 cu o putere electrică totală de 3.189 kW. Cu o eficiență totală de peste 70%, unitățile J316 îmbunătățesc securitatea alimentării cu energie a instalației, oferind în același timp căldură și apă caldă.'
            },
            record3:{
                head:'J320 Ensign Drilling Jonah Field în Wyoming, SUA',
                fuel:'Gaze Naurale',
                engine:'24 x J320',
                power:'24,168 kW',
                thermalPower:'',
                expluatation:'2011',
                text:'În sud-vestul Wyoming, un producător de gaze majore a desfăşurat 24 J320 motoare pentru a repopula o instalaţie de foraj folosind gaze naturale în loc de motorină. În câmpul Jonah, gazul disponibil permite producătorului să surprindă economiile de costuri și să reducă emisiile totale ale amplasamentului.'
            },
            record4:{
                head:'J320 Amtex Textile Center din Punjab, Pakistan',
                fuel:'Gaze Naurale',
                engine:'12 x J320',
                power:'12,072 kW',
                thermalPower:'',
                expluatation:'2002, 2003, 2004, 2005, 2008',
                text:'O duzină de unități cu gaz natural J320 generează energie electrică pentru filaturi într-unul dintre cele mai importante centre textile din Pakistan. Instalația se bazează pe capacitatea motoarelor Jenbacher de a funcționa eficient într-un mediu dificil, care include temperaturi ambiante ridicate, aer de admisie cu praf și funcționare în mod insular.'
            },
        },
        
        seria4:{
            record1:{
                head:'J420 Spitalul St Bart’s din Londra, Marea Britanie',
                fuel:'Gaze Naturale',
                engine:'1 x J420',
                power:'1,480 kW',
                thermalPower:'1,624 kW',
                expluatation:'2015',
                text:'Din 2015, unul dintre cele mai vechi spitale din Marea Britanie a obținut răcire, căldură și putere de la o singură unitate J420. Unitatea de cogenerare de 1,4 MW include un răcitor de absorbție de 250 kW care furnizează apă de răcire spitalului. Motorul J420 este piatra de temelie a unui nou centru energetic care a oferit facilități economice financiare prin creșterea eficienței energetice, a fiabilității și a durabilității.'
            },
            record2:{
                head:'J420 Ashford Power Peaking Plant în Kent, Marea Britanie',
                fuel:'Gaze Naturale',
                engine:'14 x J420',
                power:'21 MW',
                thermalPower:'',
                expluatation:'2018',
                text:'Instalația de generare a energiei electrice de la Ashford Power, Kings North Industrial Estate din Kent operează 14 motoare Jenbacher J420 containerizate. Când nu sunt în funcțiune, motoarele acestei fabrici complet automatizate așteaptă în standby, pregătite pentru a fi rechemate și intensificate în mai puțin de 2 minute.'
            },
            record3:{
                head:'J420 Sera SV.CO Strijbisverbeek în Maasdijuk, Olanda',
                fuel:'Gaze Naturale',
                engine:'1 x J420',
                power:'1,501 kW',
                thermalPower:'1,996 kW',
                expluatation:'2018',
                text:'Sera Strijbisverbeek în Maasdijuk, Olanda, se bazează pe o soluție CHP de seră CHP constând dintr-un motor Jenbacher J420, un complet sistem de evacuare de gaze inclusiv un reactor catalitic pentru CO2 și carcasă acustică. Energia generată în această seră este utilizată pentru a opera luminile pentru creștere. În plus, ei folosesc căldura CHP pentru a încălzi sera lor în perioadele mai reci și pe timp de noapte.'
            },
            record4:{
                head:'J420 Stația de Biogaz în Nakornrachasrima, Thailanda',
                fuel:'Biogaz',
                engine:'5 x J420',
                power:'7,105 kW',
                thermalPower:'',
                expluatation:'2012',
                text:'Facilitatea Chok Yuen Yong profită de cele cinci motoare J420 care asigură o putere fiabilă la fața locului, reducând în același timp costurile electrice și energetice. Excesul de energie electrică produsă este furnizat rețelei publice.'
            },
        },
        
        seria6:{
            record1:{
                head:'J616 & J620 BMW în Regensburg și Leipzig, Germania',
                fuel:'Gaze Naturale',
                engine:'4xJ616,1xJ620',
                power:'10700 kW , 3000 kW',
                thermalPower:'9600 kW , 3120 kW',
                expluatation:'2011 , 2015',
                text:'Centralele de cogenerare instalate la fabricile BMW Group** din Regensburg și Leipzig pot genera energie electrică la fața locului și pot capta și utiliza căldura reziduală a motorului pentru a susține procesele de producție ale fabricilor. Încălzirea pe timp de iarnă se obține printr-o combinație a căldurii reziduale a motoarelor și a căldurii de la cazanele existente.'
            },
            record2:{
                head:'J620 Coca-Cola Hellenic, România',
                fuel:'Gaze Naturale',
                engine:'2 x J620',
                power:'6,082 kW',
                thermalPower:'2,208 kW',
                expluatation:'2009',
                text:'Din 2009, două motoare J620 furnizează companiei Coca-Cola Hellenic Bottling Company energie și căldură, precum și apă caldă și răcită pentru operațiunile sale. Prin satisfacerea nevoilor de energie ale instalației, motoarele instalate ajută la reducerea amprentei de carbon a companiei și la reducerea costurilor operaționale globale.'
            },
            record3:{
                head:'J624 Hakha CES în Daejeon, Coreea de Sud',
                fuel:'Gaze Naturale',
                engine:'6 x J624',
                power:'25,182 kW',
                thermalPower:'25,350 kW',
                expluatation:'2014',
                text:'Cu un total de șase motoare J624 funcționând pe gaze naturale, fabrica Hakha, Daejeon ajunge la un total de 25,182 kW de energie electrică atingînd realizarea eficienței totale de 87%. Odată cu instalarea acestor motoare Jenbacher, fabrica a devenit una dintre cele mai mari fabrici de motoare cu gaz din Coreea de Sud.'
            },
            record4:{
                head:'J624 turbo cu 2 trepte Sera Serres Vinet în Machecoul, Franța',
                fuel:'Gaze Naturale',
                engine:'2xJ624 ,2-stage turbocharged',
                power:'8,800 kW',
                thermalPower:'8,024 kW',
                expluatation:'2011',
                text:'La această instalaţie cu efect de seră, două motoare Jenbacher J624 cu turbo în 2 etape permite cultivatorului francez Serres Vinet de a genera necesarul de apă caldă şi energie electrică pentru operaţiunile sale extinse de tomate şi salată cu efect de seră. Acestea sunt primele 2 motoarelor cu gaz de acest gen în Franța și permite cultivatorului Serres Vinet flexibilitatea de a comuta între sursele de energie, în dependență de ce dicteaza economia, să fie energie electrică sau energie termică.'
            },
        },
        
    },


    en:{
        secondBlockTextTitle2:'Continuous development for more than 40 years',
        secondBlockText2:'Introduced in 1976 and continuously improved, the Jenbacher* type 2 engine offers extremely high efficiency in the 250 to 350 kW power range. Its robust design and stationary engine concept result in excellent component durability and a service life of 80,000 operating hours before the first major overhaul. Enhanced components and a proven control and monitoring concept give this engine outstanding reliability.',
        
        secondBlockTextTitle3:'Efficient, durable, reliable',
        secondBlockText3:'Long service intervals, maintenance-friendly engine design and low fuel consumption ensure maximum efficiency in our type 3 Jenbacher* engines. Enhanced components prolong service life even when using non-pipeline gases, such as landfill gas. Our type 3 engines offer an outstanding service interval with up to 80,000 operating hours until the major overhaul. This engine type stands out in its 400 to 1,100 kW power range due to its technical maturity and high degree of reliability. ',
        
        secondBlockTextTitle4:'An efficiency milestone',
        secondBlockText4:'Based on the proven design concepts of types 3 and 6, the modern Jenbacher* type 4 engines in the 800 to 1,500 kW power range are characterized by a high-power density and outstanding efficiency. The enhanced control and monitoring provide easy preventive maintenance, high reliability and availability.',
        
        secondBlockTextTitle6:'Cutting-edge technology',
        secondBlockText6:'Continuously refined based on our extensive experience, Jenbacher type 6 engines are reliable, advanced products serving the 2 to 4.5 MW power range. The 1,500 rpm engine speed provides high power density and low installation costs. The type 6 pre-combustion chamber enables high efficiency with low emissions. Proven design and enhanced components support a service life of 60,000 operating hours before the first major overhaul. The J624 model features the advanced 2-stage turbocharging technology, which offers high electrical efficiency combined with improved flexibility over a wide range of ambient conditions.',
        
        secondBlockTextTitlediane:'The engine management system DIA.NE',
        secondBlockTextdiane:' DIA.NE XT4* – the new generation of engine control and visualization systems for Jenbacher gas engines from INNIO. The system consists of the I/O modules, a sturdy industrial PC (controller) and a 15" touch display, all built into the local control cabinet. For remote control, a software program "DIA.NE XT4 HMI Client" is provided for installation on any PC (including tablets running Windows 8®).',
        


        seria2:{
            record1:{
                head:'J208 Biogas plant in Schlitters, Austria',
                fuel:'Biogas',
                engine:'1 x J208',
                power:'350kW',
                thermalPower:'370kW',
                expluatation:'2008, 2015(1)',
                text:'A single J208 engine at the combined heat and power (CHP) station in Schlitters annually transforms about 12,000 tons of leftover food and biowaste into electricity and heat. The residual digested biowaste then is compressed into compost or turned into liquid manure to fertilize agricultural fields in the region.(1) Engine replaced by a new J208'
            },
            record2:{
                head:'J208 Sewage treatment plant in Fritzens, Austria',
                fuel:'Sewage gas',
                engine:'1 x J208',
                power:'660kW',
                thermalPower:'761kW',
                expluatation:'2002(1),2005(2)',
                text:'At the sewage treatment plant in Fritzens, two J208 engines running on sewage gas generate more than 3.3 MWh of electricity to offset the facility’s annual electricity demand. Furthermore, heat generated by the engines is used to process food waste and generate additional energy that benefits the plant’s waste management operations.(1) First engine (2) Second engine'
            },
            record3:{
                head:'J208 Sewage treatment plant in Strass im Zillertal, Austria',
                fuel:'Sewage gas',
                engine:'1 x J208 ,1 x J312',
                power:'625 kW',
                thermalPower:'724 kW ',
                expluatation:'2009',
                text:'Two Jenbacher gas engines generate 120% of the electricity and heat needed at the sewage treatment plant in Strass. The excess power is fed into the local grid.'
            },
            record4:{
                head:'J208 & J320 Arif Habib Group Business Building in Karachi, Pakistan',
                fuel:'Natural Gas',
                engine:'1xJ208 ,1xJ320',
                power:'330kW , 1.064kW',
                thermalPower:'',
                expluatation:'2009',
                text:'For the Arif Habib Group, a business conglomerate company based in Karachi, two Jenbacher generator sets generate 1,394 kW electricity to support their head office with onsite power. The J208 engine has already achieved more than 50,000 operating hours.'
            },
        },


        seria3:{
            record1:{
                head:'J312 & J320 Landfill Site in Durban, South Africa',
                fuel:'Landfill gas',
                engine:'1xJ312, 1xJ320',
                power:'526 kW, 1.064 kW',
                thermalPower:'',
                expluatation:'2006',
                text:'At the Durban Landfill, two containerized Jenbacher generator sets with a total electrical output of 1,590 kW generate electricity for the local municipal grid. Moreover, the use of landfill gas for power generation curbs environmental pollution and health problems associated with the escaping landfill gas.'
            },
            record2:{
                head:'J316 Combined Cooling, Heat & Power Plant at the Hospital in Beijing Qinghe, China',
                fuel:'Natural gas',
                engine:'2 x J316',
                power:'3,189 kW',
                thermalPower:'1,851 kW',
                expluatation:'2012',
                text:'The Qinghe Hospital building and facility installed two J316 engines with a total electrical output of 3,189 kW. With a total efficiency of more than 70%, the J316 units improve the facility’s energy supply security while also providing exhaust heat and hot water.'
            },
            record3:{
                head:'J320 Ensign Drilling Jonah Field in Wyoming, US',
                fuel:'Natural gas',
                engine:'24 x J320',
                power:'24,168 kW',
                thermalPower:'',
                expluatation:'2011',
                text:'In southwest Wyoming, a major gas producer has deployed 24 J320 engines to repower a drilling rig using natural gas instead of diesel. In the Jonah field, available site gas allows the producer to capture cost savings and reduce overall site emissions.'
            },
            record4:{
                head:'J320 Amtex Textile Center in Punjab, Pakistan',
                fuel:'Natural gas',
                engine:'12 x J320',
                power:'12,072 kW',
                thermalPower:'',
                expluatation:'2002, 2003, 2004, 2005, 2008',
                text:'A dozen J320 natural gas-driven units generate electricity for spinning mills in one of Pakistan’s most important textile centers. The plant relies on the Jenbacher engines’ ability to operate efficiently in a difficult environment that includes high ambient temperatures, dusty inlet air, and island mode operation.'
            },
        },
        
        
        seria4:{
            record1:{
                head:'J420 St Bart’s Hospital in London, United Kingdom',
                fuel:'Natural gas',
                engine:'1 x J420',
                power:'1,480 kW',
                thermalPower:'1,624 kW',
                expluatation:'2015',
                text:'Since 2015, one of the oldest hospitals in the UK has obtained cooling, heat and power from a single J420 unit. The 1.4 MW cogeneration unit includes a 250 kW absorption chiller that delivers cooling water to the hospital. The J420 engine is the cornerstone of a new energy center that has provided the facility with financial savings by boosting its energy efficiency, reliability and durability.'
            },
            record2:{
                head:'J420 Ashford Power Peaking Plant in Kent, United Kingdom',
                fuel:'Natural gas',
                engine:'14 x J420',
                power:'21 MW',
                thermalPower:'',
                expluatation:'2018',
                text:'The electricity generating peaking plant at Ashford Power, Kings North Industrial Estate in Kent is operating 14 containerized Jenbacher J420 engines. When not in operation, the engines of this fully-automated plant wait on standby, prepared to be called upon and ramped up in less than 2 minutes.'
            },
            record3:{
                head:'J420 SV.CO Strijbisverbeek Greenhouse in Maasdijuk, the Netherlands',
                fuel:'Natural gas',
                engine:'1 x J420',
                power:'1,501 kW',
                thermalPower:'1,996 kW',
                expluatation:'2018',
                text:'The Strijbisverbeek Greenhouse in Maasdijuk, Netherlands, is relying on a total greenhouse CHP solution consisting of a Jenbacher J420, a complete exhaust gas system incl. catalytic reactor for CO2 and acoustical enclosure. The energy generated in this greenhouse is used to operate its grow lights. Additionally, they are using the heat of the CHP to heat up their greenhouse in colder periods and at night.'
            },
            record4:{
                head:'J420 Biogas Plant in Nakornrachasrima, Thailand',
                fuel:'Биогаз',
                engine:'5 x J420',
                power:'7,105 kW',
                thermalPower:'',
                expluatation:'2012',
                text:'The Chok Yuen Yong facility profits from its five J420 engines that provide reliable on-site power while also reducing electrical and energy costs. The excess electricity produced is supplied to the public grid.'
            },
        },
        
        
        seria6:{
            record1:{
                head:'J616 & J620 BMW in Regensburg and Leipzig, Germany',
                fuel:'Natural gas',
                engine:'4xJ616,1xJ620',
                power:'10700 kW , 3000 kW',
                thermalPower:'9600 kW , 3120 kW',
                expluatation:'2011 , 2015',
                text:'The cogeneration plants installed at BMW Group**’s factories in Regensburg and Leipzig can generate on-site power and capture and use engine waste heat to support the factories’ production processes. Winter heating is obtained through a combination of the engines’ waste heat and heat from existing boilers.'
            },
            record2:{
                head:'J620 Coca-Cola Hellenic, Romania',
                fuel:'Natural gas',
                engine:'2 x J620',
                power:'6,082 kW',
                thermalPower:'2,208 kW',
                expluatation:'2009',
                text:'Since 2009, two J620 engines have been supplying the Coca-Cola Hellenic Bottling Company with energy and heat, as well as hot and chilled water for its operations. By fulfilling the facility’s on-site power needs, the installed engines help reduce the company’s carbon footprint and lower its overall operational costs.'
            },
            record3:{
                head:'J624 Hakha CES in Daejeon, South Korea',
                fuel:'Natural gas',
                engine:'6 x J624',
                power:'25,182 kW',
                thermalPower:'25,350 kW',
                expluatation:'2014',
                text:'With a total of six J624 engines running on natural gas, the Hakha, Daejeon site reaches a total of 25,182 kW of electrical output while achieving total efficiency of 87%. With the installation of these Jenbacher engines, the site has become one of the largest gas engine plants in South Korea.'
            },
            record4:{
                head:'J624 2-stage turbocharged Serres Vinet Greenhouse in Machecoul, France',
                fuel:'Natural gas',
                engine:'2xJ624 ,2-stage turbocharged',
                power:'8,800 kW',
                thermalPower:'8,024 kW',
                expluatation:'2011',
                text:'At this greenhouse facility, two Jenbacher J624 2-stage turbocharged gas engines enable French grower Serres Vinet to generate all of the hot water and electricity required for its extensive tomato and lettuce greenhouse operations. These are the first 2-stage turbocharged gas engines in France and give Serres Vinet the flexibility to switch among the energy sources to either provide electrical energy and thermal energy as economics dictate.'
            },
        },
        
    }
}