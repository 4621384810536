import React from 'react'
import MainVacancy from '../components/Vacancy/MainVacancy'
import VacancyList from '../components/Vacancy/VacancyList'

const Vacancy = () => {
    window.scrollTo(0, 0);
    return (
        <>
            <MainVacancy id="home"/>
            <VacancyList />

        </>
    )
}

export default Vacancy
