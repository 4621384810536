import React from 'react'
import { ArtBlock, ArtContainer, FirstBlockArticles, FirstScreenLayer, TextArticlesBlock , Text, ArticleImg, TitleText , Li} from '../components/ArticlesPage/ArticlesEllements'

import Co2_1 from '../img/articles/Co2.jpg'
import Cookies from 'universal-cookie';
import {ArticleLibraryText} from '../components/Library/ArticleLibrary'
const cookies = new Cookies();

const Co2 = () => {
    
    window.scrollTo(0, 0);
    return (
        <>
            <FirstBlockArticles>
                <FirstScreenLayer>
                <ArtContainer>
                    <ArtBlock>
                    {ArticleLibraryText.Co2[cookies.get('language')].title}

                    </ArtBlock>
                </ArtContainer>
                </FirstScreenLayer>
            </FirstBlockArticles> 
            <TextArticlesBlock>
                <ArtContainer>
                      <TitleText>{ArticleLibraryText.Co2[cookies.get('language')].text1}</TitleText>

                        <Text>{ArticleLibraryText.Co2[cookies.get('language')].text2}</Text>

                        <ArticleImg src={Co2_1} />

                        <TitleText>{ArticleLibraryText.Co2[cookies.get('language')].text3}</TitleText>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text4}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text5}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text6}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text7}</Li>

                        <TitleText>{ArticleLibraryText.Co2[cookies.get('language')].text8}</TitleText>
                        <Text>{ArticleLibraryText.Co2[cookies.get('language')].text9}</Text>

                        <TitleText>{ArticleLibraryText.Co2[cookies.get('language')].text10}</TitleText>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text11}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text12}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text13}</Li>

                        <TitleText>{ArticleLibraryText.Co2[cookies.get('language')].text14}</TitleText>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text15}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text16}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text17}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text18}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text19}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text20}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text21}</Li>

                        <TitleText>{ArticleLibraryText.Co2[cookies.get('language')].text22}</TitleText>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text23}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text24}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text25}</Li>
                        <Li>{ArticleLibraryText.Co2[cookies.get('language')].text26}</Li>

                        <TitleText>{ArticleLibraryText.Co2[cookies.get('language')].text27}</TitleText>
                        <Text>{ArticleLibraryText.Co2[cookies.get('language')].text28}</Text>

                </ArtContainer>
               
           </TextArticlesBlock>
        </>
    )
}

export default Co2
