import styled from 'styled-components'

export const DianeBlock = styled.div`
    width:100%;
    background: rgba(0 ,0 ,0 ,0.1);
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

`

export const DianeBlockContainer = styled.div`
    width:100%;
    max-width:1100px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`

export const DianeImage = styled.img`
    width:80%;

`

export const DianeText = styled.div`
    display: flex;
    text-align:center;
    justify-content:center;
    align-items:center;
    font-size:1.2rem;
    padding:50px;
`