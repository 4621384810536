import React from 'react'
import { FirstScreen, FirstScreenImage, FirstScreenText,FirstScreenContainer,FirstScreenLayer, FirstScreenImageContainer } from './FirstEllements'

import {LibraryModel} from '../../Library/LibraryModel'
import Cookies from 'universal-cookie'

const cookie = new Cookies();


const FirstBlock = ({ellement}) => {
    window.scrollTo(0, 0)
    let model='img/models/model'+ellement+'.png';
    let headText=LibraryModel[cookie.get('language')]['headerText'+ellement];
    return (
        <>
            <FirstScreen id='home'>
                <FirstScreenLayer >
                <FirstScreenContainer>
                    <FirstScreenText>{headText}</FirstScreenText>
                    <FirstScreenImageContainer>
                        
                        <FirstScreenImage src={model} />
                    </FirstScreenImageContainer>
                    </FirstScreenContainer>
                </FirstScreenLayer>
                
            </FirstScreen>
        </>
    )
}

export default FirstBlock
