import { FaBars } from 'react-icons/fa'
import React from 'react'
import { Nav, NavbarContainer , NavLogo ,LogoImage
        , MobileIcon , NavMenu ,NavItem ,
        NavLang , NavLink , NavLinkR , NavBtn, LangItem, LangImg, LogoBlock, InnioLink, InnioImage } from './NavbarEllements'
import en from '../../img/lang/en.png'
import md from '../../img/lang/md.png'
import ru from '../../img/lang/ru.png'

import vipropat from '../../img/vipropat.png'
import innio from '../../img/jenbacherinnio1.png'

import {MainPageText} from '../Library/LibraryMainPage'

import Cookies from 'universal-cookie';


const cookies = new Cookies();

const setRu = () => {
cookies.set('language', 'ru', { path: '/' });
window.location.reload();
}
const setEn = () => {
cookies.set('language', 'en', { path: '/' });
window.location.reload();
}
const setMd = () => {
cookies.set('language', 'md', { path: '/' });
window.location.reload();
}





const Navbar = ( {toggle}) => {
  

    const scrollWithOffset = (el, offset) => {
        const elementPosition = el.offsetTop - offset;
        window.scroll({
          top: elementPosition,
          left: 0,
          behavior: "smooth"
        }); } 
    
    

    
    return (
        <>  
            <Nav >
                <NavbarContainer>
                    <LogoBlock>   
                        <NavLogo to='/#home' 
                                    smooth={true}
                                    scroll={el => scrollWithOffset(el, 100)}
                                    duration={500}>
                            <LogoImage src={vipropat} />
                        </NavLogo>
                    </LogoBlock>


                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    
                    <NavMenu>
                        <NavItem>
                            <NavLink to="/#about" 
                                    smooth={true}
                                    scroll={el => scrollWithOffset(el, 100)}
                                    duration={1500}>
                                        {MainPageText[cookies.get('language')].about}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/#product"
                                    smooth={true}
                                    scroll={el => scrollWithOffset(el, 100)}
                                    duration={500}>
                                        {MainPageText[cookies.get('language')].equipment}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/#project" 
                                    smooth={true}
                                    scroll={el => scrollWithOffset(el, 100)}
                                    duration={500}>
                                        {MainPageText[cookies.get('language')].projects}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/#articles" 
                                    smooth={true}
                                    scroll={el => scrollWithOffset(el, 100)}
                                    duration={500}>
                                        {MainPageText[cookies.get('language')].articles}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/#contact" 
                                    smooth={true}
                                    scroll={el => scrollWithOffset(el, 100)}
                                    duration={500}>
                                        {MainPageText[cookies.get('language')].contacts}</NavLink>
                        </NavItem>
                            
                        
                    </NavMenu>
                    
                        <NavBtn>
                          <NavLinkR to="/vacancy">{MainPageText[cookies.get('language')].vacancy}</NavLinkR>
                          <NavLang>
                            <LangItem onClick={setEn}>
                                <LangImg src={en} 
                                className={cookies.get('language')==='en' ? null  : 'hideLang'  } />
                            </LangItem>
                            
                            <LangItem  onClick={setMd}>
                                <LangImg src={md}
                                className={cookies.get('language')==='md' ? null  : 'hideLang' } />
                            </LangItem>
                            
                            <LangItem  onClick={setRu}>
                                 <LangImg src={ru}
                                className={cookies.get('language')==='ru' ? null  : 'hideLang'} />
                            </LangItem>
                        </NavLang>
                        </NavBtn>
                        <LogoBlock className="logo_innio">   
                        <InnioLink href="https://www.innio.com/" >
                            <InnioImage src={innio} />
                        </InnioLink>
                    </LogoBlock>
                </NavbarContainer>
            </Nav>

        </>
    )
}

export default Navbar
