import React from 'react'
import { ArtBlock, ArtContainer, ArticleImg, FirstBlockArticles, FirstScreenLayer, Li, Text, TextArticlesBlock, TitleText } from '../components/ArticlesPage/ArticlesEllements'

import Gas1 from '../img/articles/gas.jpg'

import Cookies from 'universal-cookie';
import {ArticleLibraryText} from '../components/Library/ArticleLibrary'
const cookies = new Cookies();

const Gas = () => {
     window.scrollTo(0, 0);
    return (
        <>
            <FirstBlockArticles>
                <FirstScreenLayer>
                <ArtContainer>
                    <ArtBlock>{ArticleLibraryText.Landfill[cookies.get('language')].title}</ArtBlock>
                </ArtContainer>
                </FirstScreenLayer>
            </FirstBlockArticles> 
            <TextArticlesBlock>
                <ArtContainer>
                        <Text>{ArticleLibraryText.Landfill[cookies.get('language')].text1}</Text>
                        <Text>{ArticleLibraryText.Landfill[cookies.get('language')].text2}</Text>
                        
                        <Text>{ArticleLibraryText.Landfill[cookies.get('language')].text25}</Text>

                        <ArticleImg src={Gas1} />

                        <TitleText>{ArticleLibraryText.Landfill[cookies.get('language')].text3}</TitleText>
                        <Text>{ArticleLibraryText.Landfill[cookies.get('language')].text4}</Text>
                        <Li>{ArticleLibraryText.Landfill[cookies.get('language')].text5}</Li>
                        <Li>{ArticleLibraryText.Landfill[cookies.get('language')].text6}</Li>
                        <Li>{ArticleLibraryText.Landfill[cookies.get('language')].text7}</Li>
                        <Li>{ArticleLibraryText.Landfill[cookies.get('language')].text8}</Li>



                        <TitleText>{ArticleLibraryText.Landfill[cookies.get('language')].text9}</TitleText>
                        <Text>{ArticleLibraryText.Landfill[cookies.get('language')].text10}</Text>

                        <TitleText>{ArticleLibraryText.Landfill[cookies.get('language')].text11}</TitleText>
                        <Text>{ArticleLibraryText.Landfill[cookies.get('language')].text12}</Text>
                        <Text>{ArticleLibraryText.Landfill[cookies.get('language')].text13}</Text>
                        <Text> {ArticleLibraryText.Landfill[cookies.get('language')].text14}</Text>

                        
                        <TitleText>{ArticleLibraryText.Landfill[cookies.get('language')].text15}</TitleText>
                        <Li>{ArticleLibraryText.Landfill[cookies.get('language')].text16}</Li>
                        <Li>{ArticleLibraryText.Landfill[cookies.get('language')].text17}</Li>
                        <Li>{ArticleLibraryText.Landfill[cookies.get('language')].text18}</Li>
                        <Li>{ArticleLibraryText.Landfill[cookies.get('language')].text19}</Li>
                        <Li>{ArticleLibraryText.Landfill[cookies.get('language')].text20}</Li>


                        <TitleText>{ArticleLibraryText.Landfill[cookies.get('language')].text21}</TitleText>
                        <Text>{ArticleLibraryText.Landfill[cookies.get('language')].text22}</Text>

                        <Text>{ArticleLibraryText.Landfill[cookies.get('language')].text23}</Text>

                        <Text>{ArticleLibraryText.Landfill[cookies.get('language')].text24}</Text>
                </ArtContainer>
               
           </TextArticlesBlock>
        </>
    )
}

export default Gas
