import styled from 'styled-components'
// import {Link as LinkS } from 'react-scroll'
import {Link as LinkR } from 'react-router-dom'

import {NavHashLink as LinkS} from 'react-router-hash-link'


export const Nav = styled.div`
    width:100%;
    height:100px;
    margin-top:-100px;
    background:#fff;
    display:flex;
    justify-content:center;
    align-items:center;
    position:sticky;
    top:0;
    z-index: 10;
    font-size:1rem;

    border-bottom: 1px solid #cdcdcd;
    @media screen and (max-width : 960px) {
        transition : 0.4s ease;
        height: 60px;
    }
`


export const NavbarContainer = styled.div`
    display:flex;
    justify-content:space-between;
    height:100px;
    width: 100%;
    padding: 0 24px;
`
export const LogoBlock = styled.div`
    justify-self:flex-start;
    display:flex;
    align-items:center;
    
`

export const NavLogo = styled(LinkS)`
    display: flex;
    cursor: pointer;
    text-decoration:none;

`

export const MobileIcon = styled.div`
    display:none;

    @media screen and (max-width : 768px){
        display:block;
        position:absolute;
        top:0;
        right:0;
        transform:translate(-100% , 40%);
        cursor:pointer;
        color:black;
        font-size: 30px;
    }
`
export const NavMenu = styled.ul`
    display:flex;
    align-items:center;
    list-style:none;
    text-align:center;
    margin-right: -22px;

    @media screen and (max-width : 768px){
        display:none;
    }
`
export const NavItem = styled.li`
    height:100px;

`
export const NavLink = styled(LinkS)`
    color:#000;
    display:flex;
    align-items:center;
    text-decoration:none;
    padding:0 1rem;
    height:100%;
    cursor:pointer;
    font-weight:600;
/* 
    &.active{
        border-bottom: 5px #ff5500 solid;
        transition:.1s ease;
    } */
    
    
`
export const NavLinkR = styled(LinkR)`
    color:#000;
    display:flex;
    align-items:center;
    text-decoration:none;
    padding:0 1rem;
    height:100%;
    cursor:pointer;
    font-weight:600;
    
`

export const NavBtn = styled.nav`
    display:flex;
    align-items:center;

    @media screen and (max-width : 768px){
        display:none;
    }
` 

export const NavLang = styled.ul`
    display:flex;
    align-items:center;
    list-style:none;
    text-align:center;
    margin-right: -22px;

    @media screen and (max-width : 768px){
        display:none;
    }
`

export const LangItem = styled.li`
    width:30px;
    cursor:pointer;
    margin-left:5px;
`

export const LangImg = styled.img`
    width:100%;
    height:100%;
`

export const LogoImage = styled.img`
    width:230px;

    @media screen and (max-width : 960px) {
        width:150px;
    }
`

export const InnioLink = styled.a`

`
export const InnioImage = styled.img`
    width:180px;
    margin-left:10px;


    @media screen and (max-width : 960px) {
        width:120px;
    }
`