import styled from 'styled-components'
import {FaPhoneSquareAlt as FA , FaViber as FV , FaWhatsapp as FW} from 'react-icons/fa'


export const ContactPage = styled.div`
    width:100%;
    background:#282828;
    padding:20px 50px 70px;
    display:flex;
    
    flex-wrap:wrap;
    justify-content:space-between;
    align-items:center;
    @media screen and (max-width : 960px ){
        padding:5px ;
    }
`
export const ContactHeader = styled.span`
    width:100%;
    margin:30px;
    font-size:2rem;
    color:#dfdfdf;
    font-weight:500;
    text-align:center;
    @media screen and (max-width : 960px ){
        
    }
`

export const ContactMap = styled.div`
    border-radius:5px;
    width:33%;
    @media screen and (max-width : 960px ){
        width:100%;
    }
`

export const ContactAdress = styled.div`
    padding:30px;
    width:33%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    text-align:center;
    color:white;
    @media screen and (max-width : 960px ){
        width:100%;
        padding:10px;
    }
`
export const ContactHead = styled.span`
    
    font-weight:600;
    font-size:22px;
    margin:10px;
`

export const ContactBody = styled.span`
    
    font-weight:400;
    font-size:18px;
    
`
export const ContactPhone = styled.a`
    
    vertical-align: middle; 
    font-weight:500;
    text-decoration:none;
    color:white;
    justify-content:center;
    text-align:center;
    font-size:28px;
    margin-top:20px;
    display:flex;
    align-items:center;
    transition:.4s ease;

    &:hover{
        
        color:#ff6800;
    }
    
`
export const ContactMail = styled.a`
    
    vertical-align: middle; 
    font-weight:500;
    text-decoration:none;
    color:white;
    justify-content:center;
    text-align:center;
    font-size:20px;
    margin-top:20px;
    display:flex;
    align-items:center;
    transition:.4s ease;
    &:hover{
        color:#ff6800;
    }
    
`

export const FaPhoneSquareAlt = styled(FA)`
    
    
`

export const FaViber = styled(FV)`
    
    
`

export const FaWhatsapp = styled(FW)`
    
    
`

export const WriteHeader = styled.span`
    font-size:28px;
    font-weight:600;
    color:white;
    border-bottom:1px solid grey;
    margin:10px;
`



export const ContactWrite = styled.div`
    flex-direction:column;
    width:33%;
    padding:0 40px;
    display:flex;
    justify-content:center;
    align-items:center;

    @media screen and (max-width : 960px ){
        width:100%;
        margin-top:20px;
        margin-bottom:20px;
        padding:0 10px;
    }
`