

export const LibraryModel = {
    ru :{
        lineup:'Модельный ряд',
        headerText2:'Модельный ряд 2ой серии',
        headerText3:'Модельный ряд 3ой серии',
        headerText4:'Модельный ряд 4ой серии',
        headerText6:'Модельный ряд 6ой серии',
        headerTextdiane:'Система автоматики DIA.NE',

        dianeText:'Система выступает человеко-машинным интерфейсом при вводе в эксплуатацию, эксплуатации, техобслуживании и диагностике неисправностей газовых двигателей. Таким образом, можно в любой момент предпринять действия, необходимые для управления газовыми двигателями, – локально или дистанционно. Многозадачная операционная система реального времени обеспечивает выполнение критических по времени и важных для безопасности задач по регулировке и контролю двигателей, а также управлению двигателями. Операционная система Windows «Windows® 7 embedded» от Microsoft является подходящей платформой для визуализации и регистрации данных. В сочетании с инструментом для управления установками в онлайн-режиме myPlant™ от INNIO возможно надежное дистанционное управление установкой через Интернет.',
        
        ThirdBlock1:'Вид топлива',
        ThirdBlock2:'Тип двигателя',
        ThirdBlock3:'Электрическая мощность',
        ThirdBlock4:'Тепловая мощность',
        ThirdBlock5:'Ввод в эксплуатацию',

        FourBlock:'Просмотреть/Скачать технические данные',
    },



    md :{
        lineup:'Produse Jenbacher',
        headerText2:'Jenbacher model 2',
        headerText3:'Jenbacher model 3',
        headerText4:'Jenbacher model 4',
        headerText6:'Jenbacher model 6',
        headerTextdiane:'Sistem de automatizare DIA.NE',

        dianeText:' Sistemul acționează ca o interfață om-mașină la punerea în funcțiune, operarea, întreținerea și diagnosticarea defectelor motoarelor cu gaz. Astfel, este posibil, în orice moment, să se ia măsurile necesare pentru a controla motoarele cu gaz – local sau de la distanță. Sistemul de operare multitasking în timp real oferă sarcini critice în timp și importante pentru siguranța reglării și controlului motoarelor, precum și monitorizarea motoarelor. Sistemul de operare Windows "Windows ® 7 embedded" de la Microsoft este o platformă potrivită pentru vizualizarea și înregistrarea datelor. În combinație cu instrumentul de gestionare a instalațiilor în modul online MYPLANT ™ de la INNIO, este posibilă gestionarea fiabilă a instalării la distanță prin Internet.',
        
        ThirdBlock1:'Combustibil',
        ThirdBlock2:'Tip motor',
        ThirdBlock3:'Putere electrică',
        ThirdBlock4:'Putere termică',
        ThirdBlock5:'Punere în funcțiune',

        FourBlock:'Vizualizați / descărcați date tehnice',
    },



    en :{
        lineup:'PRODUCTS',
        headerText2:'Type 2 Gas Engines',
        headerText3:'Type 3 Gas Engines',
        headerText4:'Type 4 Gas Engines',
        headerText6:'Type 6 Gas Engines',
        headerTextdiane:'Engine Management Systems DIA.NE',

        dianeText:'The system constitutes the human-machine interface for commissioning, operation, maintenance and troubleshooting of the gas engines. This means you have the Jenbacher gas engine firmly under control at all times – directly on site or via a remote connection.A real-time multitasking operating system ensures the time-critical and safety-relevant tasks as part of engine regulation, engine monitoring and engine control. The Windows operating system "Windows® 7 Embedded" from Microsoft provides the ideal platform for visualization and data recording. Combined with the myPlant™ online plant management tool from INNIO, it assures reliable remote control of the plant over the internet.',
        
        ThirdBlock1:'Fuel',
        ThirdBlock2:'Engine type',
        ThirdBlock3:'Electrical output',
        ThirdBlock4:'Thermal output',
        ThirdBlock5:'Commissioning',

        FourBlock:'Preview/Download technical details',
    },

    
}