


export const Models = [
    {
        image:'/img/projects/p1.jpg',
        text:{
            ru: 'Республика Молдова, район Хынчешть, поселок Фырлэдень.',
            md: 'Republica Moldova, raionul Hîncești, așezarea Firladeni.',
            en: 'Republic of Moldova, Hincesti district, Firladeni settlement.',
        },
       
        link:'/Project1'
    },
    {
        image:'/img/projects/p2.jpg',
        text:{
            ru:'г.Тирасполь. Молдавия , Тепло-электро станция на базе двух контейнерных модулей JMC 420',
            md:'Tiraspol. Moldova, Centrală termică bazată pe două module de containere JMC 420',
            en:'Tiraspol. Moldova, Thermal power station based on two container modules JMC 420',
        },
        link:'/Project2'
    },
    {
        image:'/img/projects/p3.jpg',
        text:{
            ru:'г.Бендеры. Молдавия , Строительство Тепло-электро станции на базе двух модулей JMS 420',
            md:'orașul Bender. Moldova, Construcția unei centrale termice și electrice pe baza a două module JMS 420',
            en:'city ​​of Bender. Moldova, Construction of a Heating and Power Plant based on two JMS 420 modules',
        },
        link:'/Project3'
    },
    {
        image:'/img/projects/p4.jpg',
        text:{
            ru:'г.Губкинский , Ямало-Немецкий автономный округ , Электростанция собственных нужд на базе двух контейнерных модулей JGC 416',
            md:'Gubkinsky, Yamalo-Nemets Autonom Okrug, Centrală auxiliară bazată pe două module de containere JGC 416',
            en:'Gubkinsky, Yamalo-Nemets Autonomous Okrug, Auxiliary power plant based on two container modules JGC 416',
        },
        link:'/Project4'
    },
]