import { Button, InputAdornment, TextField } from '@material-ui/core'
import React from 'react'
import {  makeStyles } from '@material-ui/core/styles';
import { ContactPage ,ContactMap ,ContactAdress , ContactWrite, ContactHead,ContactBody,
         ContactPhone ,FaPhoneSquareAlt,FaViber,FaWhatsapp, ContactMail , WriteHeader ,ContactHeader} from './ContactEllements'

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import MessageIcon from '@material-ui/icons/Message';


import Cookies from 'universal-cookie';
import {MainPageText} from '../../Library/LibraryMainPage'

// import axios from 'axios'

const cookies = new Cookies();


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',

        color:'white',
        '& Mui-focused':{
          color:'white'
        },
        '& label': {
          color:'white',
          '&.Mui-focused':{
            color:'white'
          }
        },
        '& fieldset':{
          borderColor:'white',
          '&:hover , &:focus':{
            borderColor:"grey"
          }
         },
         
      '& textarea':{
        color:'white',
        '&:hover , &:focus':{
          borderColor:"grey"
        }
      },
        '&.MuiButton-outlined':{
          borderColor:'grey'
        }
      
       
      },
      
    },
    
  }));





const ContactSection = () => {
    const classes = useStyles();

    const [values, setValues] = React.useState({
      mail: '',
      name: '',
      theme: '',
      message: '',
      sent:false,
    });

    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };

    

    const resetForm = () =>{
      setValues({
        name:'',
        mail:'',
        theme:'',
        message:''
      })

      setTimeout(()=>{
        setValues({
          sent:false,
        })
      },3000)
    }



    const formSubmit=(e)=>{
      e.preventDefault();

      let data={
        name:values.name,
        mail:values.mail,
        theme:values.theme,
        message:values.message,
      }

      
      fetch('/api/back.php', {
        method : "POST" , 
        headers : {
          'Content-Type': 'application/json',
        },
        body : JSON.stringify({data})
      })
      .then(response =>response.text())
      .then(response =>{
        resetForm();
        console.log(response)
      })
    }

    
    
    return (
        <ContactPage id="contact">
           <ContactHeader>{MainPageText[cookies.get('language')].contacts}</ContactHeader>
           <ContactMap>
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d679.6166083495561!2d28.86174182920905!3d47.05069991246004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDfCsDAzJzAyLjUiTiAyOMKwNTEnNDQuMiJF!5e0!3m2!1sru!2s!4v1620285171855!5m2!1sru!2s"
                   width="100%" 
                   height="300px" 
                   title="maps"
                   loading="lazy"></iframe>
           </ContactMap>
           <ContactAdress>
                <ContactHead>{MainPageText[cookies.get('language')].contactArea}</ContactHead>
                <ContactBody>{MainPageText[cookies.get('language')].contactAdress}
                </ContactBody>
                <ContactPhone  href="tel:+37369127963">
                    <FaPhoneSquareAlt /> 
                    &nbsp;
                     +37369127963
                </ContactPhone> 
                <ContactPhone  href="https://api.whatsapp.com/send?phone=37369127963">
                    <FaWhatsapp /> 
                    &nbsp;
                    Whatsapp
                </ContactPhone>
                <ContactPhone  href="viber://chat/?number=%2B37369127963">
                    <FaViber /> 
                    &nbsp;
                     Viber
                </ContactPhone>
               
                <ContactMail  href="tel:+37369127963">
                    <i className="fas fa-at"></i>
                    &nbsp;                  
                    info@cogeneration.md
                </ContactMail>
           </ContactAdress>

           <ContactWrite>
             <WriteHeader>{MainPageText[cookies.get('language')].writeEmail}</WriteHeader>
            <form      
                       className={classes.root}
                             noValidate 
                             autoComplete="off"
                            >
                              

                        <TextField
                            id="name"
                            size="small"
                            label={MainPageText[cookies.get('language')].writeEmailAdress}
                            multiline
                            // required
                            value={values.mail}
                            onChange={handleChange('mail')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AlternateEmailIcon />
                                </InputAdornment>
                              ),
                            }}
                            />
                         <TextField
                            id="mail"
                            size="small"
                            label={MainPageText[cookies.get('language')].writeEmailName}
                            multiline
                            type="search"
                            required
                            value={values.name}
                            onChange={handleChange('name')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AccountBoxIcon />
                                </InputAdornment>
                              ),
                            }}
                            />   
                         <TextField
                            id="themas"
                            size="small"
                            label={MainPageText[cookies.get('language')].writeEmailTheme}
                            multiline
                            required
                            value={values.theme}
                            onChange={handleChange('theme')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AnnouncementIcon />
                                </InputAdornment>
                              ),
                            }}
                            />   
                         <TextField
                            size="small"
                            id="message"
                            label={MainPageText[cookies.get('language')].writeEmailMessage}
                            multiline
                            required
                            value={values.message}
                            onChange={handleChange('message')}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MessageIcon />
                                </InputAdornment>
                              ),
                            }}
                            />   

                            <Button type="submit"
                                    variant="outlined"
                                    
                             onClick={formSubmit}
                              >{MainPageText[cookies.get('language')].writeEmailSend}</Button>
            </form>

           </ContactWrite>
        </ContactPage>
    )
}

export default ContactSection
