import React from 'react'
import {MotorsPrewiev } from '../ThirdBlock/Data'
import { SecondBl ,SecondBlockTitle,SecondBlockSub } from './SecondEllements'
import Cookies from 'universal-cookie'

const cookie = new Cookies();

const SecondBlock = ({ellement}) => {

    return (
        <>
            <SecondBl>
                <SecondBlockTitle>
                     {MotorsPrewiev[cookie.get('language')]['secondBlockTextTitle'+ellement]}
                </SecondBlockTitle>
                <SecondBlockSub>
                {MotorsPrewiev[cookie.get('language')]['secondBlockText'+ellement]}
                </SecondBlockSub>
            </SecondBl>
        </>
    )
}

export default SecondBlock
