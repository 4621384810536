import React from 'react'
import { Bold, Li, VacancyBlock, VacancyContainer, VacancyItem, VacancySubText, VacancyText, VacancyTitle } from './VacansyListEllements'


import Cookies from 'universal-cookie';
import {VacancyLibrary} from '../Library'
const cookies = new Cookies();



const VacancyList = () => {
    return (
        <>
            <VacancyBlock>
                <VacancyContainer>
                    <VacancyItem>
                        <VacancyTitle>
                            {VacancyLibrary[cookies.get('language')].head1}
                            
                        </VacancyTitle>
                        <VacancyText>
                        <Bold>{VacancyLibrary[cookies.get('language')].sup1}</Bold>
                        <Li>{VacancyLibrary[cookies.get('language')].sup1_1}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sup1_2}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sup1_3}</Li>
                        </VacancyText>   

                        <VacancyText>
                        <Bold>{VacancyLibrary[cookies.get('language')].sub1}</Bold>
                        <Li>{VacancyLibrary[cookies.get('language')].sub1_1}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sub1_2}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sub1_3}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sub1_4}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sub1_5}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sub1_6}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sub1_7}</Li>
                        </VacancyText>    

                        <VacancySubText>
                        {VacancyLibrary[cookies.get('language')].subtitle1_1} 
                        {' '} 
                         <a href="mailto: info@cogeneration.md">info@cogeneration.md</a> 
                        {' '} 
                        {VacancyLibrary[cookies.get('language')].subtitle1_2}
                        
                        </VacancySubText>                                            
                    </VacancyItem>


                    <VacancyItem>
                        <VacancyTitle>
                         {VacancyLibrary[cookies.get('language')].head2}
                            
                        </VacancyTitle>
                        <VacancyText>
                        <Bold>{VacancyLibrary[cookies.get('language')].sup2}</Bold>
                        <Li>{VacancyLibrary[cookies.get('language')].sup2_1}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sup2_2}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sup2_3}</Li>
                        </VacancyText>    


                        <VacancyText>
                        <Bold>{VacancyLibrary[cookies.get('language')].sub2}</Bold>
                        <Li>{VacancyLibrary[cookies.get('language')].sub2_1}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sub2_2}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sub2_3}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sub2_4}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sub2_5}</Li>
                        <Li>{VacancyLibrary[cookies.get('language')].sub2_6}</Li>
                        </VacancyText>    

                        <VacancySubText>
                        {VacancyLibrary[cookies.get('language')].subtitle2_1} 
                        {' '} 
                         <a href="mailto: info@cogeneration.md">info@cogeneration.md</a> 
                        {' '} 
                        {VacancyLibrary[cookies.get('language')].subtitle2_2}
                        
                        </VacancySubText>                                               
                    </VacancyItem>
                </VacancyContainer>
            </VacancyBlock> 
        </>
    )
}

export default VacancyList
