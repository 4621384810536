import styled from 'styled-components'

export const FirstScreen = styled.div`
    background: #000 url('img/background.png') no-repeat;
    background-position: center;
    background-size: cover;
    


`

export const FirstScreenLayer = styled.div`
    background: rgba(0 , 0 , 0 , 0.6);
    width: 100%;
    height:100%;
    min-height:100vh;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

`

export const FirstScreenContainer = styled.div`
    width:100%;
    max-width:1100px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap:wrap;
    
`

export const FirstScreenText =styled.div`
    width:50%;
    display:flex;
    height:100%;
    justify-content:center;
    align-items:center;
    font-size:48px;
    flex-direction: column;
    text-align:center;
    font-weight:600;
    color:white;

    @media screen and (max-width : 768px ){
        width:90%;
        
    font-size:32px;
    padding-top: 100px;
    }
`

export const FirstScreenImageContainer =styled.div`
    width:50%;
    display:flex;
    justify-content:center;
    align-items:center;

    @media screen and (max-width : 768px ){
        width:90%;
    }
`

export const FirstScreenImage = styled.img`
    width:100%;
    height:100%;
    object-fit:cover;
`

export const SwiperIMG = styled.img`
   width:100%;
    height:100%;
    object-fit:cover;
`