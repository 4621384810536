import React from 'react'


import { ArtBlock, ArtContainer, FirstBlockArticles, FirstScreenLayer, Li, Text, TextArticlesBlock, TitleText } from '../components/ArticlesPage/ArticlesEllements';

import Cookies from 'universal-cookie';
import {ProjectText} from '../components/Library/ProjectLibrary'



import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import Project4_1 from '../img/projects/p4/1.jpg'
import Project4_2 from '../img/projects/p4/2.jpg'
import Project4_3 from '../img/projects/p4/3.jpg'
import Project4_4 from '../img/projects/p4/4.jpg'
import Project4_5 from '../img/projects/p4/5.jpg'
import Project4_6 from '../img/projects/p4/6.jpg'


import SwiperCore, {
    Pagination,Navigation
  } from 'swiper/core';
import { SlideImage, SwipeContent } from '../components/Projects/ProjectsEllements';
  SwiperCore.use([Pagination,Navigation]);

const cookies = new Cookies();





const Project4 = () => {
    
    window.scrollTo(0, 0);

    return (
        <>
             <FirstBlockArticles>
                <FirstScreenLayer>
                <ArtContainer>
                    <ArtBlock>
                    {ProjectText[4][cookies.get('language')].title}
                    </ArtBlock>
                </ArtContainer>
                </FirstScreenLayer>
            </FirstBlockArticles> 
            <TextArticlesBlock>
                <ArtContainer>
                      <TitleText>{ProjectText[4][cookies.get('language')].text1}</TitleText>
                        <Text>{ProjectText[4][cookies.get('language')].text2}</Text>
                        <Li>{ProjectText[4][cookies.get('language')].text3}</Li>
                        <Li>{ProjectText[4][cookies.get('language')].text4}</Li>
                        <Li>{ProjectText[4][cookies.get('language')].text5}</Li>
                        <Li>{ProjectText[4][cookies.get('language')].text6}</Li>
                        <Li>{ProjectText[4][cookies.get('language')].text7}</Li>
                        <Li>{ProjectText[4][cookies.get('language')].text8}</Li>


                        <Text>{ProjectText[4][cookies.get('language')].text9}</Text>
                        <Text>{ProjectText[4][cookies.get('language')].text10}</Text>
                        <Text>{ProjectText[4][cookies.get('language')].text11}</Text>
              



            <Swiper effect={'coverflow'} 
                        grabCursor={true} 
                        centeredSlides={true} 
                        slidesPerView={'auto'} 
                        loop={true} 
                        navigation
                        autoplay={{
                            "delay": 4000,
                            "disableOnInteraction": true
                          }} 
                        coverflowEffect={{"rotate": 30, "stretch": 0, "depth": 0,  "modifier": 1,  "slideShadows": false}} 
                        pagination={true} 
                        className="mySwiper">
                    
                    <SwiperSlide >
                        <SwipeContent href={Project4_1}  target="_blank">
                            <SlideImage src={Project4_1} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide >
                        <SwipeContent  href={Project4_2}  target="_blank">
                            <SlideImage src={Project4_2} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={Project4_3}  target="_blank">
                            <SlideImage src={Project4_3} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={Project4_4}  target="_blank">
                            <SlideImage src={Project4_4} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={Project4_5}  target="_blank">
                            <SlideImage src={Project4_5} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={Project4_6} target="_blank">
                            <SlideImage src={Project4_6} />
                        </SwipeContent>
                    </SwiperSlide>
                    
                </Swiper>

                </ArtContainer>
               
               </TextArticlesBlock>
        </>
    )
}





export default Project4

