import React from 'react'
import { ArticleItem, ArticleItems, ArticleLink, ArticlesContainer, ArticlesHeader, ArticlesHome } from './ArticlesEllements'

import Cookies from 'universal-cookie';
import {MainPageText} from '../../Library/LibraryMainPage'
const cookies = new Cookies();

const ArticlesSection = () => {
    return (
        <>
            <ArticlesHome id="articles">
                <ArticlesContainer>
                    <ArticlesHeader>{MainPageText[cookies.get('language')].articles}</ArticlesHeader>
               

                <ArticleItems>
                    <ArticleItem >
                        <ArticleLink to="/powerengine">
                        {MainPageText[cookies.get('language')].articles1}
                             
                        </ArticleLink>
                    </ArticleItem>
                    <ArticleItem >
                        <ArticleLink to="/cogeneration">
                        {MainPageText[cookies.get('language')].articles2}
                             
                        </ArticleLink>
                    </ArticleItem>
                    <ArticleItem >
                        <ArticleLink to="/co2">
                        {MainPageText[cookies.get('language')].articles3}
                             
                        </ArticleLink>
                    </ArticleItem>
                    <ArticleItem >
                        
                        <ArticleLink to="/biogas">
                        {MainPageText[cookies.get('language')].articles4}
                            
                        </ArticleLink>
                    
                    </ArticleItem>
                    <ArticleItem >
                        <ArticleLink to="/gas">
                        {MainPageText[cookies.get('language')].articles5}
                           
                        </ArticleLink>
                    </ArticleItem>
                    
                    <ArticleItem >
                        <ArticleLink to="/trigeneration">
                        {MainPageText[cookies.get('language')].articles6}
                          
                        </ArticleLink>
                    </ArticleItem>
                </ArticleItems>
                </ArticlesContainer>

            </ArticlesHome>
        </>
    )
}

export default ArticlesSection
