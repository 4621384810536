import styled from 'styled-components'
import {Link as RLink} from 'react-router-dom'


export const ArticlesHome = styled.div`
    background: rgb(215,103,3);
    background: linear-gradient(50deg ,rgba(215,103,3,1) 20%,rgba(255,255,255,1) 50%,rgba(54,164,9,1) 80%);
    width:100%;

    display:flex;
    justify-content:center;
    align-items:center;

`

export const ArticlesContainer = styled.div`

    width:100%;
    max-width:1100px;
    
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    padding:30px;
    
    @media screen and (max-width : 960px ){
        
    padding:5px;
    }
    
`
export const ArticlesHeader = styled.span`
    font-size:2rem;
    font-weight:600;
    margin-bottom:30px;

    @media screen and (max-width : 960px){
        
    margin:20px;
    }
`

export const ArticleItems = styled.div`
    display:flex;
    flex-wrap:wrap;
    
    justify-content: center;
`

export const ArticleItem = styled.div`
    width:calc(33% - 20px);
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    padding:15px;
    background:rgba( 0 , 0 , 0 , 0.9);
    
    margin:10px;
    font-size:1.4rem;
    height:200px;
    border-radius:5px;
    transition:0.4s ease;
    
    &:hover{
        
        background:rgba( 0 , 0 , 0 , 1);
        }

    @media screen and (max-width : 960px){
        width:calc(50% - 20px);
    font-size:1rem;
    
    margin:3px;
    }    

`

export const ArticleLink = styled(RLink)`
    width:100%;
    height:100%;
    color:#b3b3b3;
    display:flex;
    justify-content:center;
    align-items:center;
    text-decoration:none;
    transition:0.4s ease;

    &:hover{
        
    font-size:1.5rem;
    color:white;
    }

    @media screen and (max-width : 960px){
        &:hover{
        
        font-size:1.1rem;
        }
    }    

`

