
import './App.css';

import React , { useState } from 'react';
import { BrowserRouter as Router, Route, Switch  } from 'react-router-dom'

import PhoneBtn from './components/MainPage/PhoneBtn'

import Home from './pages';
import model2 from './pages/model2';
import model3 from './pages/model3';
import model4 from './pages/model4';
import model6 from './pages/model6';
import diane from './pages/diane';

import Vacancy from './pages/vacancy';

import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Cookies from 'universal-cookie'
import BtnToTop from './components/MainPage/BtnToTop';


import News1 from './pages/news1'
import News2 from './pages/news2'
import News3 from './pages/news3'

import Biogas from './pages/biogas'
import Co2 from './pages/co2'
import Cogeneration from './pages/cogeneration'
import Gas from './pages/gas'
import Powerengine from './pages/powerengine'
import Trigeneration from './pages/trigeneration'

import Project1 from './pages/Project1'
import Project2 from './pages/Project2'
import Project3 from './pages/Project3'
import Project4 from './pages/Project4'

const cookies = new Cookies();
cookies.get('language') ? console.log('Selected language is ',cookies.get('language'))  : cookies.set('language', 'ru', { path: '/' })

function App() {
  const [isOpen , setIsOpen] = useState(false);
  const toggle =() =>{
    setIsOpen(!isOpen)
  }
  

  return (
    <>
    
    <Router>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
         <Navbar  toggle={toggle} />
        <PhoneBtn />
        <BtnToTop />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/model2" component={model2}  exact />

          <Route path="/model3"   component={model3} exact />  
          <Route path="/model4"   component={model4} exact />  
          <Route path="/model6"   component={model6} exact />  
          <Route path="/diane"    component={diane} exact />
          <Route path="/vacancy"  component={Vacancy} exact />
          <Route path="/news1" component={News1} exact />
          <Route path="/news2" component={News2} exact />
          <Route path="/news3" component={News3} exact />

          <Route path="/cogeneration" component={Cogeneration} exact />
          <Route path="/powerengine" component={Powerengine} exact />
          <Route path="/co2" component={Co2} exact />
          <Route path="/biogas" component={Biogas} exact />
          <Route path="/gas" component={Gas} exact />
          <Route path="/trigeneration" component={Trigeneration} exact />

          <Route path="/Project1" component={Project1} exact />
          <Route path="/Project2" component={Project2} exact />
          <Route path="/Project3" component={Project3} exact />
          <Route path="/Project4" component={Project4} exact />
               
        </Switch>
        
        <Footer />
    </Router>
    </>
  );
}
export default App;
