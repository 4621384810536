import React from 'react'
import { VacancyHeadText ,VacancyBlock, VacancyLayer} from './MainVacancyEllements'


import Cookies from 'universal-cookie';
import {VacancyLibrary} from '../Library'
const cookies = new Cookies();


const MainVacancy = () => {
    return (
        <>
            <VacancyBlock>
                <VacancyLayer>
                <VacancyHeadText>
                      {VacancyLibrary[cookies.get('language')].title}
                </VacancyHeadText>
                </VacancyLayer>
            </VacancyBlock>
        </>
    )
}

export default MainVacancy
