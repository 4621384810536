import React from 'react'
import { LogoImage, MainContainer, MainLogo, MainScreen, MainText ,HeadText , MidleLogo,Midle, HomeLayer} from './HomeSectionEllements'

import innio from '../../../img/jenbacherinnio.png'
import jenbacher from '../../../img/jenbacher6.png'

import Cookies from 'universal-cookie';
import {MainPageText} from '../../Library/LibraryMainPage'

const cookies = new Cookies();
const HomeSection = () => {
    return (
       <MainScreen id="home">
           <HomeLayer>
               
           <MainContainer>
                <MainText>
                    <HeadText>
                    {MainPageText[cookies.get('language')].homeHead}    
                    </HeadText>
                    <Midle>
                        <MidleLogo src={innio} />
                    </Midle>
                    <HeadText>
                    {MainPageText[cookies.get('language')].homeTitle} 
                    </HeadText>
                </MainText>

                <MainLogo>
                    <LogoImage src={jenbacher}/>
                </MainLogo>
           </MainContainer>
           
           </HomeLayer>
       </MainScreen>
    )
}

export default HomeSection
