import React from 'react'
import { FirstNews, NewsContainer, NewsHeadTitle, NewsScreenLayer ,News,
    AdditionalText, NewsText, NewsContainerText, Li, NewsAdditionalText, LiText, NewsFullImage} from '../components/NewsPages/NewsEllements'

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"


import News1_1 from '../img/news/news1.jpg'
import News1_2 from '../img/news/news1_2.jpg'
import News1_3 from '../img/news/news1_3.jpg'
import News1_4 from '../img/news/news1_4.jpg'
import News1_5 from '../img/news/news1_5.jpg'

import Cookies from 'universal-cookie';
import {NewsLibraryText} from '../components/Library/NewsLibrary'



import SwiperCore, {
    Pagination,Navigation
  } from 'swiper/core';
import { SlideImage, SwipeContent } from '../components/Projects/ProjectsEllements';
  SwiperCore.use([Pagination,Navigation]);

  const cookies = new Cookies();

const News1 = () => {

    window.scrollTo(0, 0);
      
    return (
        <>
            <News>
                
            <FirstNews>

                <NewsScreenLayer>
                     <NewsContainer>
                        <NewsHeadTitle>
                        {NewsLibraryText.news1[cookies.get('language')].title}
                        </NewsHeadTitle>
                    </NewsContainer>

                   
                </NewsScreenLayer> 
            </FirstNews> 
            

            <NewsContainerText>

            <NewsText></NewsText>
            <NewsText>
            {NewsLibraryText.news1[cookies.get('language')].text1}
                </NewsText>



                <Swiper effect={'coverflow'} 
                        grabCursor={true} 
                        centeredSlides={true} 
                        slidesPerView={'auto'} 
                        loop={true} 
                        navigation
                        autoplay={{
                            "delay": 4000,
                            "disableOnInteraction": true
                          }} 
                        coverflowEffect={{"rotate": 30, "stretch": 0, "depth": 0,  "modifier": 1,  "slideShadows": false}} 
                        pagination={true} 
                        className="mySwiper">
                    
                    <SwiperSlide >
                        <SwipeContent href={News1_1}  target="_blank">
                            <SlideImage src={News1_1} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide >
                        <SwipeContent  href={News1_2}  target="_blank">
                            <SlideImage src={News1_2} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={News1_3}  target="_blank">
                            <SlideImage src={News1_3} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={News1_4}  target="_blank">
                            <SlideImage src={News1_4} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={News1_5}  target="_blank">
                            <SlideImage src={News1_5} />
                        </SwipeContent>
                    </SwiperSlide>
                    
                </Swiper>

           
              
            <NewsText>
                <strong>
                    
            {NewsLibraryText.news1[cookies.get('language')].text2}
            </strong>   
            </NewsText>  

            <NewsAdditionalText>
            <AdditionalText>
                
            {NewsLibraryText.news1[cookies.get('language')].text3}
               
            </AdditionalText>
            <Li>
                
            {NewsLibraryText.news1[cookies.get('language')].text4}
                </Li>
            <LiText>
                
            {NewsLibraryText.news1[cookies.get('language')].text5}
               </LiText>
            <Li>
                
            {NewsLibraryText.news1[cookies.get('language')].text6}
                </Li>
            <LiText>
                
            {NewsLibraryText.news1[cookies.get('language')].text7}</LiText>
            <Li>
                
            {NewsLibraryText.news1[cookies.get('language')].text8}</Li>
            <LiText>
                
            {NewsLibraryText.news1[cookies.get('language')].text9}</LiText>
            </NewsAdditionalText>
            

            <NewsText>
                
            {NewsLibraryText.news1[cookies.get('language')].text10}</NewsText>
            <NewsFullImage src={News1_5} />
           </NewsContainerText>
            </News>
        </>
    )
}

export default News1
