import { FaChevronCircleUp } from "react-icons/fa";


import React , {useState} from 'react'
import { BtnUp } from './BtnEllements'

import './Btn.css'

const BtnToTop = () => {

    const [active , setActive ] = useState(false);
    let magic = () => {
            if (window.pageYOffset > 300) {
                setActive(true);
            } else { setActive(false);}
        };

    window.onscroll = magic;
    window.onload = magic;

    const top = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
    }
    return (
        <BtnUp  
                
                onClick={top}
                smooth={true}
                offset={-60}
                duration={500}
                title="up"
                className={active ? 'btn_active' : 'btn_inactive'}>
                <FaChevronCircleUp />
        </BtnUp>
    )
}

export default BtnToTop
