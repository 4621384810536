import styled from 'styled-components'

export const FooterContent = styled.div`
    width:100%;
        padding:15px;
    background:#00933b;
    color:white;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:14px;
    color:#000000;
    text-align:center;

    @media screen and (max-width : 960px){
        
        font-size:13px;
        padding:10px 50px;
    }

`