import styled from 'styled-components'

export const VacancyBlock = styled.div`
    display:flex;
    width:100%;
    justify-content:center;
    align-items:center;

`

export const VacancyContainer = styled.div`
    display:flex;
    width:100%;
    max-width:1100px;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    padding:50px;

    @media screen and (max-width : 960px){
        padding: 15px;
    }
`

export const VacancyItem = styled.div`
    display:flex;
    flex-direction:column;
    /* justify-content:center; */
    /* align-items:center; */
    width:100%;

    padding-bottom:40px;
    margin-bottom:40px;
    border-bottom:1px solid black;

`

export const VacancyTitle = styled.span`
    font-size:1.5rem;
    font-weight:600;
    text-decoration:underline;
`

export const VacancyText = styled.div`
    margin:10px 0;
`

export const Bold = styled.span`
    font-weight:700;
`

export const Li = styled.li`
    list-style-type: decimal;
    margin-left:20px;
`

export const VacancySubText = styled.div`

`