import React from 'react'
import { AreaBox, AreaContainer, AreaPage, BoxHeader,BoxText ,Li,LiGlobal ,BoxLogoImage,BoxForLogo } from './AreaOfUseEllements'

import logo from '../../../img/jenbacherinnio.png'

import Cookies from 'universal-cookie';
import {MainPageText} from '../../Library/LibraryMainPage'

const cookies = new Cookies();
const AreaOfUse = () => {
    return (
        <AreaPage id="areaofuse">
            <AreaContainer>
                <AreaBox>
                    <BoxHeader>{MainPageText[cookies.get('language')].aouLeftH}</BoxHeader>
                    <BoxText>
                        <ul>
                            
                        </ul>
                        <LiGlobal>{MainPageText[cookies.get('language')].aouLeftT1}</LiGlobal>
                    
                        <LiGlobal>{MainPageText[cookies.get('language')].aouLeftT2}</LiGlobal>
                    
                        <LiGlobal>
                        {MainPageText[cookies.get('language')].aouLeftT3}
                        </LiGlobal>              
                                      <Li>{MainPageText[cookies.get('language')].aouLeftLi1}</Li>
                                      <Li>{MainPageText[cookies.get('language')].aouLeftLi2}</Li>
                                      <Li>{MainPageText[cookies.get('language')].aouLeftLi3}</Li>
                                      <Li>{MainPageText[cookies.get('language')].aouLeftLi4}</Li>
                                      <Li>{MainPageText[cookies.get('language')].aouLeftLi5}</Li>
                       
                                          
                                    <LiGlobal> 
                                      {MainPageText[cookies.get('language')].aouLeftT4}
                                    </LiGlobal> 
                            </BoxText>
                            <BoxText> 
                                      {MainPageText[cookies.get('language')].aouLeftT5}
                            </BoxText>
                            <BoxText> 
                                      {MainPageText[cookies.get('language')].aouLeftT6}
                             </BoxText>
                            <BoxText> 
                                      {MainPageText[cookies.get('language')].aouLeftT7}
                            </BoxText>
                </AreaBox>
                <AreaBox>
                    <BoxHeader>
                         {MainPageText[cookies.get('language')].aouRightH}
                    </BoxHeader>
                    <BoxText>
                            <Li> {MainPageText[cookies.get('language')].aouRightLi1}</Li>
                            <Li> {MainPageText[cookies.get('language')].aouRightLi2}</Li>
                            <Li> {MainPageText[cookies.get('language')].aouRightLi3}</Li>
                            <Li> {MainPageText[cookies.get('language')].aouRightLi4}</Li>
                    </BoxText>
                    <BoxForLogo>
                     <BoxLogoImage src={logo} />
                    </BoxForLogo>
                </AreaBox>
            </AreaContainer>
        </AreaPage>
    )
}

export default AreaOfUse
