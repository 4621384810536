import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa'

// import {Link as LinkS } from 'react-scroll'

import {NavHashLink as LinkS} from 'react-router-hash-link'
import {Link as LinkR } from 'react-router-dom'

export const SidebarContainer = styled.div`
    position:fixed;
    z-index:999;
    width:100%;
    height:100%;
    background:#0d0d0d;
    display:grid;
    align-items:center;
    left:0;
    transition:0.4s ease-in-out;
    opacity:${({isOpen}) => (isOpen ? '1' : '0')};
    top:${({isOpen}) => (isOpen ? '0' : '-100%')};


`

export const CloseIcon = styled(FaTimes)`
    color:#fff;

`

export const Icon = styled.div`
    position:absolute;
    top:1.2rem;
    right:1.5rem;
    background:transparent;
    font-size:2rem;
    cursor:pointer;
    outline:none;
    `

export const SidebarWrapper = styled.div`
    color:#fff;
`

export const SidebarLink = styled(LinkS)`
    font-size:30px;
    margin:20px;
    text-decoration:none;
    color:#fff;
    transition:0.4s ease;
    cursor:pointer;

    &:hover{
        color:#01bf71;
    }
`
export const SidebarLinkR = styled(LinkR)`
    font-size:30px;
    margin:40px;
    color:#fff;
    text-decoration:none;
    cursor:pointer;
`

export const SidebarMenu =  styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`

export const SidebarLang = styled.ul`
 display:flex;
 list-style:none;

`
export const SidebarLangItem = styled.li`
    width:50px;
    margin:10px;
    
    cursor:pointer;
`
export const SidebarLangImg = styled.img`
    width:100%;
    height: 100%;

`