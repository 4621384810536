export const MainPageText = {
    ru:{
        about:'О Нас',
        equipment:'Оборудование',
        projects:'Проекты',
        contacts:'Контакты',
        vacancy:'Вакансии',


        homeHead:'ДИСТРИБЬЮТОР И СЕРВИС ПАРТНЕР',
        homeTitle:'В Молдове',

        secondTitle:'Компания VIPROPAT SRL',
        secondP1:'Основное направление деятельности компании -',
        secondT1:'проектирование, поставка, монтаж, пусконаладка, гарантийное и сервисное обслуживание газопоршневых электростанций, когенерационных и тригенерационных установок на базе оборудования Австрийской фирмы INNIO Jenbacher. Компания Vipropat является уполномоченным дистрибьютором и поставщиком услуг в Молдове от Австрийской фирмы INNIO Jenbacher.',
        secondP2:'Мы предлагаем современные источники производства электрической и тепловой энергии ',
        secondT2:' (когенерация), а при необходимости и холода (тригенерация) на базе газо-поршневых электростанций производства INNIO Jenbacher (Австрия). У нас есть все необходимые возможности для выполнения работ «под ключ».',

        aouLeftH:'Области применения:',
        aouLeftT1:'Источники энергоснабжения районных и городских централизованных систем;',
        aouLeftT2:'Пиковые блок-станции;',
        aouLeftT3:'Индивидуальные системы электро и теплоснабжения для:',
        aouLeftLi1:'предприятий промышленности и сельского хозяйства',
        aouLeftLi2:'гостиниц, баз отдыха и спортивных центров',
        aouLeftLi3:'супер и гипер маркетов, торговых центров',
        aouLeftLi4:'жилых массивов и административных комплексов',
        aouLeftLi5:'больниц, лечебных заведений',
        aouLeftLi6:'портов и аэропортов',
        aouLeftT4:'Составная часть комбинированных систем энергообеспечения на базе возобновляемых источников энергии.',
        aouLeftT5:'Используя индивидуальный и гибкий подход к каждому клиенту, мы выполняем обязательную предпроектную проработку каждого объекта с целью предоставления клиенту информации о сроках окупаемости и экономической эффективности проекта.',
        aouLeftT6:'Миссия нашей компании — развитие энергетики и укрепление энергетической безопасности Республики Молдова.',
        aouLeftT7:'Установка собственной мини ТЭЦ сделает Ваш бизнес более стабильным, независимым и прибыльным за счет надежного энергоснабжения по выгодным тарифам.',


        aouRightH:'Диапазон технических решений:',
        aouRightLi1:'Электрическая мощность энергоблоков от 300 кВт до 30 МВт.',
        aouRightLi2:'Исполнение энергоблоков - Контейнерное, модульное.',
        aouRightLi3:'Топливо- природный газ, биогаз, сжиженный газ (пропан).',
        aouRightLi4:'Выходное напряжение – 0,4 кВ, 6,3 кВ, 10 кВ.',

        project:'Наши Проекты',

        news:'Новости',
        news1:'С 17 по 20 марта в Международном выставочном центре “Moldexpo” прошла XIV-я Международная специализированная выставка энергосберегающих технологий, систем отопления, газоснабжения, кондиционирования воздуха “MOLDENERGY-2010”....',
        news2:'INNIO завершает отделение от General Electric и превращается в независимую энергетическую Компанию',
        news3:'1 сентября 2013, В Бендерах состоялся запуск когенерационной установки ГУП «Биохим...',

        articles:'Статьи',
        articles1:'Мощностной ряд газопоршневого когенерационного оборудования',
        articles2:'Когенерация',
        articles3:'Использование CO2 в теплицах',
        articles4:'Биогаз',
        articles5:'Газ мусорных свалок',
        articles6:'Тригенерация',


        contactArea:'Мы находимся',
        contactAdress:'Молдова, MD 2068, г. Кишинев, ул Мирон Костин 7(правое крыло), оф 408',

        writeEmail:'Напишите Нам!',
        writeEmailName:'Ваше Имя',
        writeEmailAdress:'Электронная почта',
        writeEmailTheme:'Тема',
        writeEmailMessage:'Сообщение',
        writeEmailSend:'ОТПРАВИТЬ',

    },


    md:{
        about:'Despre Noi',
        equipment:'Produse',
        projects:'Proiectele',
        contacts:'Date de contact',
        vacancy:'Locuri de Munca',


        homeHead:'DISTRIBUITOR ȘI PARTENER DE SERVICIU',
        homeTitle:'In Moldova',

        secondTitle:'Compania VIPROPAT SRL',
        secondP1:'Principala activitate a companiei -',
        secondT1:'proiectarea, furnizarea, instalarea, punerea în funcțiune, garanția și service-ul centralelor cu motoare, a centralelor de cogenerare și trigenerare, utilizând sistemele companiei austriece INNIO Jenbacher. Compania Vipropat este distribuitor autorizat și furnizor de servicii în Moldova a companiei austriace INNIO Jenbacher.',
        secondP2:'Oferim surse moderne de energie și căldură ',
        secondT2:' (cogenerare) și, dacă este necesar, de răcire (trigenerare), pe baza puterii motorului pe gaz a INNIO Jenbacher (Austria). Avem toate posibilitățile necesare pentru a efectua lucrările la cheie.',

        aouLeftH:'Domenii de aplicare:',
        aouLeftT1:'Surse de aprovizionare a sistemelor centralizate raionale și urbane;',
        aouLeftT2:'Stații generatoare de vârf;',
        aouLeftT3:'Sisteme individuale de alimentare cu energie electrică și termică pentru:',
        aouLeftLi1:'producătorii industriali şi agricultura',
        aouLeftLi2:'hoteluri, centre de recreere și centre sportive',
        aouLeftLi3:'super și hiper piețe, mall-uri',
        aouLeftLi4:'zone rezidențiale și complexe administrative',
        aouLeftLi5:'spitale, instituții medicale',
        aouLeftLi6:'porturi și aeroporturi',
        aouLeftT4:'Componentă a sistemelor energetice combinate bazate pe surse regenerabile de energie.',
        aouLeftT5:'Aplicând o abordare individuală și flexibilă pentru fiecare client, purtăm o pre-proiectare obligatorie a fiecărui obiect pentru a furniza clientului informații despre momentul amortizării și eficiența costurilor proiectului.',
        aouLeftT6:'Misiunea companiei noastre – dezvoltarea sectorului energetic și sporirea securității energetice a Republicii Moldova.',
        aouLeftT7:'Setarea propriei centrale electrice va face afacerea dvs. mai stabilă, independentă și profitabilă în detrimentul energiei fiabile la prețuri competitive.',

        aouRightH:'Gama de soluții tehnice:',
        aouRightLi1:'Puterea de ieșire electrică de la 300 kW la 30 MW.',
        aouRightLi2:'Unități de execuție - container, modular.',
        aouRightLi3:'Combustibil - gaz natural, biogaz, gaz petrolier lichefiat (propan).',
        aouRightLi4:'Tensiune de ieșire - 0,4 kV, 6,3 kV, 10 kV.',

        project:'Proiectele noastre',

        news:'NOUTĂȚI',
        news1:'În perioada 17 - 20 martie la Centrul Internațional de Expoziții „Moldexpo” a avut loc cea de-a XIV-a Expoziție Internațională Specializată de Tehnologii de Economisire a Energiei, Sisteme de Încălzire, Alimentare cu Gaz, Climatizare „MOLDENERGY-2010” ....',
        news2:'INNIO finalizează separarea de General Electric și devine o companie independentă de energie',
        news3:'1 septembrie 2013, În Bendery, lansarea unității de cogenerare a întreprinderii unitare de stat „Biochem ...',

        articles:'Articole',
        articles1:'Gama de putere a echipamentelor de cogenerare cu piston pe gaz',
        articles2:'Cogenerare',
        articles3:'Utilizarea CO2 în sere',
        articles4:'Biogaz',
        articles5:'Gaz de depozitare',
        articles6:'Trigenerare',
        contactArea:'Locația noastră',
        contactAdress:'Moldova, MD 2068, or. Chisinau , str. Miron Costin 7(aripa dreaptă), of 408',

        writeEmail:'Scrieți-ne!',
        writeEmailName:'Numele dumneavoastră',
        writeEmailAdress:'Email',
        writeEmailTheme:'Temă',
        writeEmailMessage:'Mesaj',
        writeEmailSend:'TRIMITE',

    },


    en:{
        about:'About US',
        equipment:'Products',
        projects:'Projects',
        contacts:'Contacts',
        vacancy:'Vacancy',


        homeHead:'DISTRIBUTOR AND SERVICE PARTNER',
        homeTitle:'IN MOLDOVA',

        secondTitle:'VIPROPAT SRL Company',
        secondP1:' The main company’s activity - ',
        secondT1:'design, supply, installation, commissioning, warranty and service of engine power plants, cogeneration and trigeneration plants, using Austrian company INNIO Jenbacher systems. Vipropat company is authorized distributor and service provider in Moldova from the Austrian company INNIO Jenbacher.',
        secondP2:'We offer modern sources of power and heat (cogeneration), ',
        secondT2:' and if necessary, of cooling (trigeneration), based on gas engine power output of INNIO Jenbacher (Austria). We have all the necessary possibilites to perform the work on a turnkey basis.',

        aouLeftH:'Application areas:',
        aouLeftT1:'Supply sources of district and urban centralized systems;',
        aouLeftT2:'Peak generating stations;',
        aouLeftT3:'Individual electricity and heat supply systems for:',
        aouLeftLi1:'industrial manufacturers and agricultur',
        aouLeftLi2:'hotels, recreation centers and sports centers',
        aouLeftLi3:'super and hyper markets, malls',
        aouLeftLi4:'residential areas and administrative complexes',
        aouLeftLi5:'hospitals, medical institutions',
        aouLeftLi6:'ports and airports',
        aouLeftT4:'Component of the combined energy systems based on renewable sources of energy.',
        aouLeftT5:'Applying an individual and flexible approach to each client, we carry a mandatory pre-design of each object to provide to the client information on the timing of payback, and project cost effectiveness.',
        aouLeftT6:'Our company`s mission – developing energetics and energy sector, and enhancing energy security of the Republic of Moldova.',
        aouLeftT7:'Setting your own power station will make your business more stable, independent and profitable at the expense of reliable energy at competitive prices.',


        aouRightH:'The range of technical solutions:',
        aouRightLi1:'Electrical output power from 300 kW to 30 MW.',
        aouRightLi2:'Execution units - container, modular.',
        aouRightLi3:'Fuel - natural gas, biogas, liquefied petroleum gas (propane).',
        aouRightLi4:'Output voltage - 0.4 kV, 6.3 kV, 10 kV.',

        project:'Our Projects',

        news:'NEWS',
        news1:'From 17 to 20 March at the International Exhibition Center “Moldexpo” the XIVth International Specialized Exhibition of Energy Saving Technologies, Heating Systems, Gas Supply, Air Conditioning “MOLDENERGY-2010” was held ....',
        news2:'INNIO completes separation from General Electric and becomes an independent energy company',
        news3:'September 1, 2013, In Bendery, the launch of the cogeneration unit of the State Unitary Enterprise "Biochem ...',



        articles:'Articles',
        articles1:'Power range of gas-piston cogeneration equipment',
        articles2:'Cogeneration',
        articles3:'CO2 use in greenhouses',
        articles4:'Biogas',
        articles5:'Landfill gas',
        articles6:'Trigeneration',


        contactArea:'Our Location',
        contactAdress:'Molova, MD 2068, Chisinau, Miron Costin str. (right wing), 7 , of. 408',

        writeEmail:'Write to Us!',
        writeEmailName:'Your Name',
        writeEmailAdress:'Your Mail',
        writeEmailTheme:'Subject',
        writeEmailMessage:'Message',
        writeEmailSend:'SEND',

    },


    
}