import styled from 'styled-components'




export const MainScreen = styled.div`
    background: #000 url('img/background.png') no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height:100vh;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    
`


export const HomeLayer = styled.div`
    width:100%;
    height:100%;
    background: rgba(0 , 0 , 0 , 0.4);

`

export const MainContainer = styled.div`
    width:100%;
    height:100%;
    padding:100px;
    display:flex;
    justify-content:center;
    
    align-items:center;
    position: relative;
    
    @media screen and (max-width : 768px ){
        
        padding:100px 5px 30px;
        flex-direction:column;
    }
`

export const MainText = styled.div`
    width:50%;
    display:flex;
    height:100%;
    justify-content:center;
    align-items:center;
    
    flex-direction: column;

    @media screen and (max-width : 768px ){
        width:90%;
        padding-top:100px;
    }
`

export const MainLogo = styled.div`
    width:50%;
    display:flex;
    height:100%;
    justify-content:center;
    align-items:center;
    @media screen and (max-width : 768px ){
        width:90%;
    }
`

export const LogoImage = styled.img`
    width:90%;
    -webkit-filter: drop-shadow(3px 5px 50px #000);
  filter: drop-shadow(3px 5px 50px #000);
  @media screen and (max-width : 768px ){
        width:100%;
    }

`

export const Midle = styled.div`
    display:flex;
    margin:20px 0;
    justify-content:center;
    align-items:center;
    @media screen and (max-width : 768px ){
       flex-direction:column;
        
    }
`

export const MidleLogo = styled.img`
    width:70%;
    height:auto;
    margin:10px;
    /* filter: drop-shadow(2px 2px 5px #eee); */
`
export const MidleText = styled.img`
   width:230px;
    height:auto;
    filter: drop-shadow(2px 2px 5px #eee);
`

export const HeadText = styled.span`
    font-size:38px;
    text-align:center;
    font-weight: 700;
    color:#E0E0E0;

    @media screen and (max-width : 768px ){
        font-size:30px;
    }
`
