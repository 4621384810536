import styled from 'styled-components'

// import {Link } from 'react-scroll'

export const BtnUp = styled.button`
    
    position: fixed;
    z-index: 10;
    bottom: 30px;
    right: 30px;
    font-size:40px;
    transition:0.4s ease;
    background: none;
    border:none;
    &:hover{
        color:rgb(255 104 0);
        transform: scaleX(-1);
    }
`