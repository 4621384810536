import styled from 'styled-components'

import TableRow from '@material-ui/core/TableRow'


export const Row =styled(TableRow)`
    transition: 0.3s ease;
    &:hover{
        background: grey;
        color:white !important;
        border:10px;
        border-radius: 10px;
    }
`

export const FirstBlockArticles = styled.div`
    background: #000 url('img/background.png') no-repeat;
    background-position: center;
    background-size: cover;
    width:100%;
    
    display: flex;
    justify-content: center;
`


export const FirstScreenLayer = styled.div`
    background: rgba(0 , 0 , 0 , 0.6);
    width: 100%;
    height:100%;
    min-height:100vh;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

`

export const ArtContainer = styled.div`
    width:100%;
    max-width:1100px;
    
    display: flex;
    justify-content: center;
    flex-direction: column;
` 

export const ArtBlock = styled.div`
    min-height:100vh;
    display:flex;
    justify-content:center;
    align-items:center;
    
    text-align: center;
    font-size:2rem;
    font-weight:600;

    color:white;

    @media screen and (max-width : 960px){
        width:90%;
        margin: auto;
    }
`


export const TextArticlesBlock = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    padding:50px;

    @media screen and (max-width : 960px){
        padding:10px;
    }

`

export const Text = styled.p`
    font-size:1.1rem;
    padding:15px;
`

export const TitleText = styled.p`
    padding:15px 10px 5px;
    font-size:1.3rem;

    font-weight:600;
    text-decoration:underline;

`

export const ArticleImg = styled.img`
    width:100%;
    padding:50px;

    @media screen and (max-width : 960px){
        padding:10px;
    }
`

export const Li = styled.li`
    list-style-type:circle;
    margin-left:20px;
    text-decoration:underline;
`