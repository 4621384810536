import styled from 'styled-components'

export const SecondBl = styled.div`
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    text-align:center;
    background:rgba(0 , 0 , 0 , 0.1);
    padding:50px 120px; 

    
    @media screen and (max-width : 960px){
        padding:10px 10px;
        width:100%;
    }
`
export const SecondBlockTitle = styled.span`
    
    font-size:28px;
    font-weight:bold;

    
    @media screen and (max-width : 960px){
        padding:10px;
        font-size:1.4rem;
        width:100%;
    }
`
export const SecondBlockSub = styled.span`
    font-size:20px;
    margin:10px;
    
    @media screen and (max-width : 960px){
        padding:10px;
        width:100%;
    }
`