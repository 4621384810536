import React from 'react'


import { ArtBlock, ArtContainer, ArticleImg, FirstBlockArticles, FirstScreenLayer,  Text, TextArticlesBlock, TitleText } from '../components/ArticlesPage/ArticlesEllements';

import Cookies from 'universal-cookie';
import {ProjectText} from '../components/Library/ProjectLibrary'



import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import Project3_1 from '../img/projects/p3/1.png'
import Project3_2 from '../img/projects/p3/2.png'
import Project3_3 from '../img/projects/p3/3.png'
import Project3_4 from '../img/projects/p3/4.png'
import Project3_5 from '../img/projects/p3/5.png'
import Project3_6 from '../img/projects/p3/6.png'
import Project3_7 from '../img/projects/p3/7.png'
import Project3_8 from '../img/projects/p3/8.png'
import Project3_9 from '../img/projects/p3/9.png'


import SwiperCore, {
    Pagination,Navigation
  } from 'swiper/core';
import { SlideImage, SwipeContent } from '../components/Projects/ProjectsEllements';
  SwiperCore.use([Pagination,Navigation]);

const cookies = new Cookies();





const Project3 = () => {
    
    window.scrollTo(0, 0);

    return (
        <>
             <FirstBlockArticles>
                <FirstScreenLayer>
                <ArtContainer>
                    <ArtBlock>
                    {ProjectText[3][cookies.get('language')].title}
                    </ArtBlock>
                </ArtContainer>
                </FirstScreenLayer>
            </FirstBlockArticles> 
            <TextArticlesBlock>
                <ArtContainer>
                      <TitleText>{ProjectText[3][cookies.get('language')].text1}</TitleText>
                      
                        <ArticleImg src={Project3_9} />

                        <Text>{ProjectText[3][cookies.get('language')].text2}</Text>

                        <Text>{ProjectText[3][cookies.get('language')].text3}</Text>

                        <Swiper effect={'coverflow'} 
                        grabCursor={true} 
                        centeredSlides={true} 
                        slidesPerView={'auto'} 
                        loop={true} 
                        navigation
                        autoplay={{
                            "delay": 4000,
                            "disableOnInteraction": true
                          }} 
                        coverflowEffect={{"rotate": 30, "stretch": 0, "depth": 0,  "modifier": 1,  "slideShadows": false}} 
                        pagination={true} 
                        className="mySwiper">
                    
                    <SwiperSlide >
                        <SwipeContent href={Project3_1}  target="_blank">
                            <SlideImage src={Project3_1} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide >
                        <SwipeContent  href={Project3_2}  target="_blank">
                            <SlideImage src={Project3_2} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={Project3_3}  target="_blank">
                            <SlideImage src={Project3_3} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={Project3_4}  target="_blank">
                            <SlideImage src={Project3_4} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={Project3_5}  target="_blank">
                            <SlideImage src={Project3_5} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={Project3_6} target="_blank">
                            <SlideImage src={Project3_6} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={Project3_7} target="_blank">
                            <SlideImage src={Project3_7} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={Project3_8} target="_blank">
                            <SlideImage src={Project3_8} />
                        </SwipeContent>
                    </SwiperSlide>
                    
                </Swiper>

                        
                        <Text>{ProjectText[3][cookies.get('language')].text4}</Text>
                </ArtContainer>
               
           </TextArticlesBlock>



           

        </>
    )
}





export default Project3

