import React from 'react'
import { ArtBlock, ArtContainer, ArticleImg, FirstBlockArticles, FirstScreenLayer, Li, Text, TextArticlesBlock, TitleText } from '../components/ArticlesPage/ArticlesEllements'

import Biogas_1 from '../img/articles/biogas_1.jpg'
import Biogas_2 from '../img/articles/biogas_2.jpg'


import Cookies from 'universal-cookie';
import {ArticleLibraryText} from '../components/Library/ArticleLibrary'
const cookies = new Cookies();

const Biogas = () => {
    
    window.scrollTo(0, 0);
    return (
        <>
            <FirstBlockArticles>
                <FirstScreenLayer>
                <ArtContainer>
                    <ArtBlock>
                      {ArticleLibraryText.Biogas[cookies.get('language')].title}
                    </ArtBlock>
                </ArtContainer>
                </FirstScreenLayer>
            </FirstBlockArticles> 
            <TextArticlesBlock>
                <ArtContainer>
                        <TitleText>{ArticleLibraryText.Biogas[cookies.get('language')].text1}</TitleText>
                        <Text>{ArticleLibraryText.Biogas[cookies.get('language')].text2}</Text>

                        <ArticleImg src={Biogas_1} />

                        <TitleText>{ArticleLibraryText.Biogas[cookies.get('language')].text3}</TitleText>
                        <Text>{ArticleLibraryText.Biogas[cookies.get('language')].text4}</Text>

                        <TitleText>{ArticleLibraryText.Biogas[cookies.get('language')].text5}</TitleText>
                        <Text>{ArticleLibraryText.Biogas[cookies.get('language')].text6}</Text>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text7}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text8}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text9}</Li>

                        <Text>{ArticleLibraryText.Biogas[cookies.get('language')].text10}</Text>
                        <Text>{ArticleLibraryText.Biogas[cookies.get('language')].text11}</Text>
                        
                        <ArticleImg src={Biogas_2} />

                        <TitleText>{ArticleLibraryText.Biogas[cookies.get('language')].text12}</TitleText>
                        <Text>{ArticleLibraryText.Biogas[cookies.get('language')].text13}</Text>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text14}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text15}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text16}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text17}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text18}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text19}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text20}</Li>

                        <Text>{ArticleLibraryText.Biogas[cookies.get('language')].text21}</Text>
                        <Text>{ArticleLibraryText.Biogas[cookies.get('language')].text22}</Text>

                        <TitleText>{ArticleLibraryText.Biogas[cookies.get('language')].text23}</TitleText>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text24}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text25}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text26}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text27}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text28}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text29}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text30}</Li>
                        <Li>{ArticleLibraryText.Biogas[cookies.get('language')].text31}</Li>

                        <TitleText>{ArticleLibraryText.Biogas[cookies.get('language')].text32}</TitleText>
                        <Text>{ArticleLibraryText.Biogas[cookies.get('language')].text33}</Text>


                 </ArtContainer>
             </TextArticlesBlock>
        </>
    )
}

export default Biogas
