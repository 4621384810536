import styled from 'styled-components'

export const BtnPhone = styled.a`
    width:70px;
    height:70px;
    position: fixed;
    bottom: 40px;
    left:30px;
    z-index:10;
    transition:0.4s ease;
    
    transform:rotate(30deg);
    &:hover{
    transform:rotate(0deg);
    }

    @media screen and (max-width : 768px ){
    width:55px;
    height:55px;
    bottom: 20px;
    left:20px;
    }
`

export const BtnImage = styled.img`
    
`