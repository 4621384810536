import React from 'react'
import { FooterContent } from './FooterEllements'

const Footer = () => {
    return (
        <FooterContent>
            Vipropat 2005-2021. ALL RIGHT RESERVED . Made by  V-Ncompany
        </FooterContent>
    )
}

export default Footer
