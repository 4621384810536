import React from 'react'
import { CloseIcon, Icon, SidebarContainer ,SidebarLang,
    SidebarLangImg,SidebarLangItem,
    SidebarWrapper , SidebarMenu , SidebarLink, SidebarLinkR} from './SidebarEllements'
  
    import en from '../../img/lang/en.png'
    import md from '../../img/lang/md.png'
    import ru from '../../img/lang/ru.png'


    import {MainPageText} from '../Library/LibraryMainPage'
    import Cookies from 'universal-cookie';


    const cookies = new Cookies();

    const setRu = () => {
        cookies.set('language', 'ru', { path: '/' });
        window.location.reload();
        }
        const setEn = () => {
        cookies.set('language', 'en', { path: '/' });
        window.location.reload();
        }
        const setMd = () => {
        cookies.set('language', 'md', { path: '/' });
        window.location.reload();
        }
        


const Sidebar = ( {isOpen , toggle}) => {

    
    const scrollWithOffset = (el, offset) => {
        const elementPosition = el.offsetTop - offset;
        window.scroll({
          top: elementPosition,
          left: 0,
          behavior: "smooth"
        }); } 



    return (
        <SidebarContainer isOpen={isOpen}>
            <Icon>
                <CloseIcon onClick={toggle} />
            </Icon>
            <SidebarWrapper  onClick={toggle}>
                <SidebarMenu>
                    <SidebarLink to="/#about"  scroll={el => scrollWithOffset(el, 60)}
                     onClick={toggle}>
                    {MainPageText[cookies.get('language')].about}
                    </SidebarLink>
                    <SidebarLink to="/#product"  scroll={el => scrollWithOffset(el, 60)} onClick={toggle}>
                    {MainPageText[cookies.get('language')].equipment}
                    </SidebarLink>
                    <SidebarLink to="/#project"  scroll={el => scrollWithOffset(el, 60)} onClick={toggle}>
                    {MainPageText[cookies.get('language')].projects}
                    </SidebarLink>
                    <SidebarLink to="/#articles"  scroll={el => scrollWithOffset(el, 60)} onClick={toggle}>
                    {MainPageText[cookies.get('language')].articles}
                    </SidebarLink>
                    <SidebarLink to="/#contact"  scroll={el => scrollWithOffset(el, 60)} onClick={toggle}>
                    {MainPageText[cookies.get('language')].contacts}
                    </SidebarLink>

                    <SidebarLinkR to="/vacancy"  onClick={toggle}>
                    {MainPageText[cookies.get('language')].vacancy}
                    </SidebarLinkR>
                    

                    <SidebarLang>
                            <SidebarLangItem onClick={setEn}>
                                <SidebarLangImg src={en} 
                                                onClick={toggle}
                                                className={cookies.get('language')==='en' ? null  : 'hideLang' }/>
                            </SidebarLangItem>
                            
                            <SidebarLangItem onClick={setMd}>
                                <SidebarLangImg src={md}  
                                                onClick={toggle}
                                                className={cookies.get('language')==='md' ? null  : 'hideLang' }/>
                            </SidebarLangItem>
                            
                            <SidebarLangItem onClick={setRu}>
                                 <SidebarLangImg src={ru}  
                                                onClick={toggle}
                                                className={cookies.get('language')==='ru' ? null  : 'hideLang' }/>
                            </SidebarLangItem>
                        </SidebarLang>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
