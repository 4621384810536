


export const Models = [
    {
        image:'/img/models/model2.png',
        id:'2',
        link:'/model2'
    },
    {
        image:'/img/models/model3.png',
        id:'3',
        link:'/model3'
    },
    {
        image:'/img/models/model4.png',
        id:'4',
        link:'/model4'
    },
    {
        image:'/img/models/model6.png',
        id:'6',
        link:'/model6'
    },
    {
        image:'/img/models/diane.png',
        id:'diane',
        link:'/diane'
    },
]

