export const VacancyLibrary={
    ru:{
        title:'Вакансии',
        head1:'Инженер энергетик',
        sup1:'Знания и опыт в области:',
        sup1_1:'Силовые электрические машины, силовые сети.',
        sup1_2:'Электроавтоматика и КИП.',
        sup1_3:'Информационные технологии.',
        sub1:'Требования к кандидату на должность инженера – энергетика:',
        sub1_1:'Высшее образование с опытом работы по специальности не менее 3 лет',
        sub1_2:'Использование ПК, продвинутый уровень (Поддержка сайта, системное администрирование).',
        sub1_3:'Знание технического английского или немецкого языков для работы с документацией.',
        sub1_4:'Грамотный русский язык.',
        sub1_5:'Знание ПК на уровне продвинутого пользователя – предпочтительны навыки системного администрирования.',
        sub1_6:'Возможность выезжать в командировки продолжительностью до 2х месяцев.',
        sub1_7:'Знание стандартных форм отчетностей, навыки делопроизводства.',
        subtitle1_1:'После трудоустройства и в течение последующей трудовой деятельности Фирма периодически направляет специалиста на курсы повышения квалификации. Размер заработной платы обсуждается при собеседовании. Резюме направлять на адрес',
        subtitle1_2:" с пометкой \"Инженер-энергетик\".",



        head2:'Инженер-механик (специалист по двигателям внутреннего сгорания)',
        sup2:'Основные обязанности:',
        sup2_1:'Самостоятельное или в составе бригады проведение сервисного обслуживания оборудования GE Jenbacher в соответствии с регламентом. Ведение отчетной документации. Составление заявок на запасные части и расходные материалы в соответствии с графиком технического обслуживания.',
        sup2_2:'Самостоятельное или в составе бригады выполнение пуско-наладочных работ на строящихся объектах.',
        sup2_3:'Самостоятельное или в составе бригады выполнение аварийных ремонтов.',
        sub2:'Требования к кандидату на должность инженера – механика:',
        sub2_1:'Высшее или среднее техническое с опытом работы по специальности не менее 3 лет образование по специальности эксплуатация двигателей внутреннего сгорания.',
        sub2_2:'Знание технического Английского или Немецкого языков в объеме необходимом для работы с Документацией.',
        sub2_3:'Знание Русского языка в пределах не менее программы средней школы.',
        sub2_4:'Умение работать на компьютере - уровень пользователя.',
        sub2_5:'Возможность работать в командировках продолжительностью до 2х месяцев.',
        sub2_6:'Соблюдать правила отчетности и правила работы с Документацией принятые в Фирме.',
        subtitle2_1:'После трудоустройства и в течение последующей трудовой деятельности Фирма периодически направляет специалиста на курсы повышения квалификации. Размер заработной платы обсуждается при собеседовании. Резюме направлять на адрес',
        subtitle2_2:'с пометкой "Инженер-механик".',

    },

    md:{
        title:'Posturi vacante',
        head1:'Inginer energetic',
        sup1:'Cunoștințe și experiență în domeniu:',
        sup1_1:'Mașini electrice de putere, rețele de alimentare.',
        sup1_2:'Electroautomatică și instrumentație.',
        sup1_3:'Tehnologia de informație.',
        sub1:'Cerințe pentru un candidat la funcția de inginer electric:',
        sub1_1:'Învățământ superior cu cel puțin 3 ani de experiență în specialitate',
        sub1_2:'Utilizarea unui PC, nivel avansat (suport site, administrare sistem).',
        sub1_3:'Cunoașterea limbii engleze sau germane tehnice pentru lucrul cu documentația.',
        sub1_4:'Competent rus.',
        sub1_5:'Cunoștințe avansate în PC - sunt preferate abilitățile de administrare a sistemului.',
        sub1_6:'Capacitatea de a merge în călătorii de afaceri până la 2 luni.',
        sub1_7:'Cunoașterea formularelor standard de raportare, abilități clericale.',
        subtitle1_1:'După angajare și în timpul angajării ulterioare, firma trimite periodic un specialist la cursuri de perfecționare. Salariul este discutat în timpul interviului. Trimiteți CV-ul la adresa',
        subtitle1_2:'marcat cu „Inginer electric”.',

        head2:'Inginer mecanic (Specialist în motoare cu ardere internă)',
        sup2:'Responsabilitati principale:',
        sup2_1:'Întreținerea echipamentelor GE Jenbacher în mod independent sau ca parte a unei echipe în conformitate cu reglementările. Menținerea documentației contabile. Redactarea comenzilor pentru piese de schimb și consumabile în conformitate cu programul de întreținere.',
        sup2_2:'În mod independent sau ca parte a unei echipe, punerea în funcțiune funcționează la instalațiile aflate în construcție.',
        sup2_3:'Efectuarea reparațiilor de urgență independent sau ca parte a unei echipe.',
        sub2:'Cerințe pentru un candidat la postul de inginer mecanic:',
        sub2_1:'Tehnic superior sau secundar cu cel puțin 3 ani de experiență în specialitate; educație în specialitatea de funcționare a motoarelor cu ardere internă.',
        sub2_2:'Cunoașterea limbii engleze sau germane tehnice în măsura necesară pentru a lucra cu documentația.',
        sub2_3:'Cunoașterea limbii rusești în limitele cel puțin a programului școlar secundar.',
        sub2_4:'Capacitatea de a lucra pe un computer este la nivel de utilizator.',
        sub2_5:'Capacitatea de a lucra în călătorii de afaceri până la 2 luni.',
        sub2_6:'Respectați regulile de raportare și regulile de lucru cu documentația adoptată în firmă.',
        subtitle2_1:'După angajare și în timpul angajării ulterioare, firma trimite periodic un specialist la cursuri de perfecționare. Salariul este discutat în timpul interviului. Trimiteți CV-ul la adresa',
        subtitle2_2:'marcat cu „Inginer mecanic”.',

    },

    en:{
        title:'Vacancies',
        head1:'Energy Engineer',
        sup1:'Knowledge and experience in the field:',
        sup1_1:'Power electrical machines, power networks.',
        sup1_2:'Electroautomatics and instrumentation.',
        sup1_3:'Information Technology.',
        sub1:'Requirements for a candidate for the position of power engineer:',
        sub1_1:'Higher education with at least 3 years of experience in the specialty',
        sub1_2:'Using a PC, advanced level (Site support, system administration).',
        sub1_3:'Knowledge of technical English or German for working with documentation.',
        sub1_4:'Competent Russian language.',
        sub1_5:'Advanced PC knowledge - System administration skills are preferred.',
        sub1_6:'The ability to go on business trips for up to 2 months.',
        sub1_7:'Knowledge of standard reporting forms, clerical skills.',
        subtitle1_1:'After employment and during subsequent employment, the Firm periodically sends a specialist to refresher courses. The size of the salary is discussed during the interview. Send your resume to the address',
        subtitle1_2:' marked "Power Engineer".',

        head2:'Mechanical Engineer (Internal Combustion Engine Specialist)',
        sup2:'Main responsibilities:',
        sup2_1:'Servicing GE Jenbacher equipment independently or as part of a team in accordance with the regulations. Maintaining accounting documentation. Drawing up orders for spare parts and consumables in accordance with the maintenance schedule.',
        sup2_2:'Independently or as part of a brigade, commissioning works at facilities under construction.',
        sup2_3:'Performing emergency repairs independently or as part of a team.',
        sub2:'Requirements for a candidate for the position of mechanical engineer:',
        sub2_1:'Higher or secondary technical with at least 3 years of experience in the specialty; education in the specialty of operating internal combustion engines.',
        sub2_2:'Knowledge of technical English or German to the extent necessary to work with the Documentation.',
        sub2_3:'Knowledge of the Russian language within the limits of the secondary school curriculum.',
        sub2_4:'The ability to work on a computer is a user level.',
        sub2_5:'The ability to work on business trips for up to 2 months.',
        sub2_6:'Observe the reporting rules and the rules for working with Documentation adopted in the Firm.',
        subtitle2_1:'After employment and during subsequent employment, the Firm periodically sends a specialist to refresher courses. The size of the salary is discussed during the interview. Send your resume to the address',
        subtitle2_2:'marked "Mechanical Engineer".',

    },
}