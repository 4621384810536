import React from 'react'
import { DianeBlock, DianeBlockContainer, DianeImage, DianeText } from './DianeEllements'
import Diane from '../../../img/models/diane.png'
import {LibraryModel} from '../../Library/LibraryModel'



import Cookies from 'universal-cookie'

const cookie = new Cookies();

const ThirdBlockDiane = () => {
    return (
        <>
            <DianeBlock>
                <DianeBlockContainer>
                    <DianeImage src={Diane} alt='DIA.NE SISTEM' />
                    <DianeText>
                            {LibraryModel[cookie.get('language')].dianeText}
                    </DianeText>
                </DianeBlockContainer>
            </DianeBlock>
        </>
    )
}

export default ThirdBlockDiane
