import styled from 'styled-components'

export const AreaPage = styled.div`
    width:100%;
    padding:50px 0;
    display:flex;
    justify-content:center;
    align-items:center;

    background: #EFEFEF;
` 

export const AreaContainer = styled.div`
    display:flex;
    width:100%;
    max-width:1100px;

    flex-wrap:wrap;

`

export const AreaBox = styled.div`
    display:flex;
    width:50%;
    padding:0 25px;
    height:100%;
    flex-direction:column;
    @media screen and (max-width : 768px){
        width:100%;
    }
`

export const BoxHeader = styled.span`
    font-size:2rem;
    font-weight:600;
    margin-bottom:10px;
    text-align:center;

`
export const BoxText = styled.span`
    font-size:1.1rem;
    font-weight:400;
    margin-bottom:10px;
    text-indent: 1em;
`
export const Li = styled.li`
    margin-left:20px;
    margin-bottom:5px;
    list-style-type: square;
    font-style:italic;
    text-decoration: underline;
    font-weight:500;
    font-size:1rem;
`
export const LiGlobal = styled.li`
    margin:5px;
    list-style-type: upper-roman;;
    
    font-weight:600;
    font-size:1rem;
`

export const BoxLogoImage = styled.img`
   width:100%;
`
export const BoxForLogo = styled.div`
    width:100%;
    height:100%;
    margin:100px 0;
    display:flex;
    justify-content:center;
    align-items:center;

    @media screen and (max-width:768px){
        
    margin:20px 0;
    }
`
