import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import { TehnicalBlock, TehnicalContainer ,Image} from './FourEllements'

import SaveIcon from '@material-ui/icons/Save';

import {LibraryModel} from '../../Library/LibraryModel'



import Cookies from 'universal-cookie'

const cookie = new Cookies();

const useStyles = makeStyles((theme) => ({
    button: {

      margin: theme.spacing(2),
    },
  }));

const FourBlock = ({ellement}) => {
    const classes = useStyles();
    let link = '/pdf/technical'+ellement+'-'+cookie.get('language')+'.pdf';
    let linkI = '/img/technical/technical'+ellement+'-'+cookie.get('language')+'.png';

    return (
        <>
            <TehnicalBlock>
               <TehnicalContainer>
               <Button
                    href={link}
                    target="_blank"
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<SaveIcon />}
                >
                    {LibraryModel[cookie.get('language')].FourBlock}
                </Button>
                <Image src={linkI} />
               </TehnicalContainer>
            </TehnicalBlock> 
        </>
    )
}

export default FourBlock
