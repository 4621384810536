import styled from 'styled-components'

import {Link as RLink} from 'react-router-dom'

export const NewsPage= styled.div`
    width:100%;
    height:100%;
    display:flex;
    background:#EFEFEF;
    justify-content:center;
`

export const NewsContainer= styled.div`

    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:95%;
    
`
export const NewsHeader= styled.div`
    margin:60px auto 0;
    font-size:2rem;
    font-weight:600;
`
export const NewsContent= styled.div`
    margin:60px auto;
    font-size:2rem;
    font-weight:600;

    display:flex;
    justify-content:center;
    flex-wrap:wrap;

`

export const NewsFirstItem = styled(RLink)`
    width:calc(50% - 10px);
    margin-right:10px;
    margin-bottom:10px;
    padding:18px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-decoration:none;
    color:black;
    
    border:1px solid #8e8e8e;
    border-radius:5px;
    flex-direction:column;

    @media screen and (max-width : 960px){
        width:95%; 
        margin-right:0px;
    }
`

export const FistImage = styled.img`
    width:90%;
    margin:10px;
`

export const FirstText = styled.span`
    font-size:20px;

    padding:10px 30px;
`

export const NewsSecond = styled.div`
    width:calc(50% - 10px);
    margin-left:10px;
    /* justify-content:center; */
    align-items:center;
    display:flex;
    flex-direction:column;

    @media screen and (max-width : 960px){
        width:95%;
        margin-left:0px;
        
    }
`

export const Second = styled(RLink)`
    width:calc(100% - 10px);
    padding:40px;
    margin:5px;
    text-decoration:none;
    display:flex;
    flex-direction:column;
    color:black;
    
    border:1px solid #8e8e8e;
    border-radius:5px;
    
    margin-bottom:10px;

    @media screen and (max-width : 960px ){
        height:100%;
    }
`

export const SecondImg = styled.img`
    width:50%;
    margin-bottom:30px;


`

export const SecondText = styled.span`
    font-size:1.2rem;
`