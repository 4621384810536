import styled from 'styled-components'



export const SwipeContent= styled.a`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    color: #000;
    text-decoration:none;
`

export const SlideImage= styled.img`
   width:100%;
   height: 100%;
   object-fit: cover;
`