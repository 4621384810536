import React from 'react'
import { BlockMotorsContainer, BlockMotorsHead, BlockWithMotord ,BlockMotorsDescriptions , BlockMotorsImages , Image, BlockMotorsText} from './ThirdEllements'

import {MotorsPrewiev} from './Data'
import { Grid } from '@material-ui/core';

import {LibraryModel} from '../../Library/LibraryModel'

import './style.css'

import Cookies from 'universal-cookie'

const cookie = new Cookies();


const ThirdBlocl = ({ellement , record , modif}) => {
    let image='/img/work/seria'+ellement+'/record'+record+'.png';
    return (
        <>  
             <BlockWithMotord className={modif? 'background_color' : ''}>
                <BlockMotorsContainer>

                    <BlockMotorsHead>{MotorsPrewiev[cookie.get('language')].['seria'+ellement].['record'+record].head}</BlockMotorsHead>
                    <BlockMotorsDescriptions >
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={7}>
                            {LibraryModel[cookie.get('language')].ThirdBlock1}
                        </Grid>
                        <Grid item xs={6} sm={5}>
                            {MotorsPrewiev[cookie.get('language')].['seria'+ellement].['record'+record].fuel}
                        </Grid>

                        <Grid item xs={6} sm={7}>
                            {LibraryModel[cookie.get('language')].ThirdBlock2}
                        </Grid>
                        <Grid item xs={6} sm={5}>
                            {MotorsPrewiev[cookie.get('language')].['seria'+ellement].['record'+record].engine}
                        </Grid>

                        <Grid item xs={6} sm={7}>
                            {LibraryModel[cookie.get('language')].ThirdBlock3}
                        </Grid>
                        <Grid item xs={6} sm={5}>
                            {MotorsPrewiev[cookie.get('language')].['seria'+ellement].['record'+record].power}
                        </Grid>

                        <Grid item xs={6} sm={7}>
                            {LibraryModel[cookie.get('language')].ThirdBlock4}
                        </Grid>
                        <Grid item xs={6} sm={5}>
                            {MotorsPrewiev[cookie.get('language')].['seria'+ellement].['record'+record].thermalPower}
                        </Grid>

                        <Grid item xs={6} sm={7}>
                            {LibraryModel[cookie.get('language')].ThirdBlock5}
                        </Grid>
                        <Grid item xs={6} sm={5}>
                            {MotorsPrewiev[cookie.get('language')].['seria'+ellement].['record'+record].expluatation}
                        </Grid>
                    </Grid>     

                    </BlockMotorsDescriptions>
                    <BlockMotorsImages >
                        <Image src={image} />
                    </BlockMotorsImages>
                    <BlockMotorsText>
                         {MotorsPrewiev[cookie.get('language')].['seria'+ellement].['record'+record].text}
                    </BlockMotorsText>
                </BlockMotorsContainer>   
            </BlockWithMotord> 
        </>
    )
}

export default ThirdBlocl
