import React  from 'react'
import FirstBlock from '../components/SeriaModel/FirstBlock'
import FourBlock from '../components/SeriaModel/FourBlock'
import SecondBlock from '../components/SeriaModel/SecondBlock'
import ThirdBlock from '../components/SeriaModel/ThirdBlock'

const model4 = () => {
  
  
    window.scrollTo(0, 0);
    return (
        <>  
            
            <FirstBlock ellement={4} />
            <SecondBlock ellement={4} />

            <ThirdBlock ellement={4} record={1} modif={true}/>
            <ThirdBlock ellement={4} record={2} modif={false}/>
            <ThirdBlock ellement={4} record={3} modif={true}/>
            <ThirdBlock ellement={4} record={4} modif={false}/>
            
            <FourBlock ellement={4}/>
        </>
    )
}

export default model4
