import React from 'react'
import { BtnImage, BtnPhone } from './PhoneEllements'
import phone from '../../../img/phone.svg'

const PhoneBtn = () => {
    return (
        <BtnPhone href="tel:+37369127963">
            <BtnImage src={phone}/>
        </BtnPhone>
    )
}

export default PhoneBtn
