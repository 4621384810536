import styled from 'styled-components'

import {Link} from 'react-router-dom'

export const ProductPage= styled.div`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    background: rgb(215,103,3);
    background: linear-gradient(50deg, rgba(215,103,3,1) 20%, rgba(255,255,255,1) 50%, rgba(54,164,9,1) 80%);
`

export const ProductContainer= styled.div`

    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:95%;
    
`
export const ProductHeader= styled.div`
    margin:60px auto 0;
    font-size:2rem;
    font-weight:600;
`
export const SwipeContent= styled(Link)`
    padding:5px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    color: #000;
    text-decoration:none;
`

export const SlideText= styled.div`
    text-align:center;
    margin-top:10px;
    font-size:1.2rem;
    font-weight:600;
`
export const SlideImage= styled.img`
   width:95%;
   border-radius:10px;
`