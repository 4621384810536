import React  from 'react'
import FirstBlock from '../components/SeriaModel/FirstBlock'
import FourBlock from '../components/SeriaModel/FourBlock'
import SecondBlock from '../components/SeriaModel/SecondBlock'
import ThirdBlock from '../components/SeriaModel/ThirdBlock'



const model3 = () => {
  

    window.scrollTo(0, 0);
    return (
        <>  
            
            <FirstBlock ellement={3} />
            <SecondBlock ellement={3} />

            <ThirdBlock ellement={3} record={1} modif={true}/>
            <ThirdBlock ellement={3} record={2} modif={false}/>
            <ThirdBlock ellement={3} record={3} modif={true}/>
            <ThirdBlock ellement={3} record={4} modif={false}/>
            
            <FourBlock ellement={3}/>

        </>
    )
}

export default model3
