import React from 'react'
import { ArtBlock, ArtContainer, FirstBlockArticles, FirstScreenLayer, TextArticlesBlock , Text, ArticleImg, TitleText , Li} from '../components/ArticlesPage/ArticlesEllements'

import Cogeneration1 from '../img/articles/cogeneration.jpg'
import Cookies from 'universal-cookie';
import {ArticleLibraryText} from '../components/Library/ArticleLibrary'
const cookies = new Cookies();

const Cogeneration = () => {
    
    window.scrollTo(0, 0);
    return (
        <>
            <FirstBlockArticles id="home">
                <FirstScreenLayer>
                <ArtContainer>
                    <ArtBlock>
                    {ArticleLibraryText.Cogeneration[cookies.get('language')].title}   
                    </ArtBlock>
                </ArtContainer>
                </FirstScreenLayer>
            </FirstBlockArticles> 
            <TextArticlesBlock>
                <ArtContainer>
                        <Text>{ArticleLibraryText.Cogeneration[cookies.get('language')].text1}</Text>

                        <ArticleImg src={Cogeneration1} />

                        <TitleText>{ArticleLibraryText.Cogeneration[cookies.get('language')].text2}</TitleText>
                        <Text> {ArticleLibraryText.Cogeneration[cookies.get('language')].text3}</Text>
                        <Text>{ArticleLibraryText.Cogeneration[cookies.get('language')].text4}</Text>
                        
                        
                        <TitleText>{ArticleLibraryText.Cogeneration[cookies.get('language')].text5}</TitleText>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text6}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text7}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text8}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text9}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text10}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text11}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text12}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text13}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text14}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text15}</Li>

                        <TitleText>{ArticleLibraryText.Cogeneration[cookies.get('language')].text16}</TitleText>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text17}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text18}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text19}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text20}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text21}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text22}</Li>
                        <Text> {ArticleLibraryText.Cogeneration[cookies.get('language')].text23}</Text>

                        <TitleText>{ArticleLibraryText.Cogeneration[cookies.get('language')].text24}</TitleText>
                        <Text>{ArticleLibraryText.Cogeneration[cookies.get('language')].text25}</Text>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text26}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text27}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text28}</Li>
                        <Li>{ArticleLibraryText.Cogeneration[cookies.get('language')].text29}</Li>

                        <TitleText>{ArticleLibraryText.Cogeneration[cookies.get('language')].text30}</TitleText>
                        <Text>{ArticleLibraryText.Cogeneration[cookies.get('language')].text31}</Text>

                        <Text>{ArticleLibraryText.Cogeneration[cookies.get('language')].text32}</Text>

                        <Text>{ArticleLibraryText.Cogeneration[cookies.get('language')].text33}</Text>
                </ArtContainer>
               
           </TextArticlesBlock>
        </>
    )
}

export default Cogeneration
