import React from 'react'
import { FirstNews, NewsHeadTitle, NewsScreenLayer , NewsContainer, NewsText, Li,NewsContainerText, News } from '../components/NewsPages/NewsEllements'

import Cookies from 'universal-cookie';
import {NewsLibraryText} from '../components/Library/NewsLibrary'

  const cookies = new Cookies();




const News2 = () => {
    
    window.scrollTo(0, 0);
    return (
        <>  <News>
            <FirstNews>

                <NewsScreenLayer>
                    <NewsContainer>
                        <NewsHeadTitle>
                        {NewsLibraryText.news2[cookies.get('language')].title}
                        
                        </NewsHeadTitle>
                    </NewsContainer>
                </NewsScreenLayer> 
            </FirstNews> 
            <NewsContainerText>
                <NewsText>{NewsLibraryText.news2[cookies.get('language')].text1}</NewsText>
                <NewsText>{NewsLibraryText.news2[cookies.get('language')].text2}</NewsText>
                <Li>{NewsLibraryText.news2[cookies.get('language')].text3}</Li>
                <Li>{NewsLibraryText.news2[cookies.get('language')].text4}</Li>
                <Li>{NewsLibraryText.news2[cookies.get('language')].text5}</Li>
                <NewsText>{NewsLibraryText.news2[cookies.get('language')].text6}</NewsText>
                <NewsText>{NewsLibraryText.news2[cookies.get('language')].text7}</NewsText>    
                <NewsText>{NewsLibraryText.news2[cookies.get('language')].text8}</NewsText>    
                <NewsText>{NewsLibraryText.news2[cookies.get('language')].text9}</NewsText>    
                <NewsText>{NewsLibraryText.news2[cookies.get('language')].text10}</NewsText>    
            </NewsContainerText>
            </News>
        </>
    )
}

export default News2
