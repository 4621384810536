import React from 'react'
import FirstBlock from '../components/SeriaModel/FirstBlock'
import SecondBlock from '../components/SeriaModel/SecondBlock'
import ThirdBlockDiane from '../components/SeriaModel/ThirdBlockDiane'

const diane = () => {
    
    window.scrollTo(0, 0);
    return (
        <>
            
            <FirstBlock ellement={'diane'} />
            <SecondBlock ellement={'diane'} />
            <ThirdBlockDiane />
        </>
    )
}

export default diane
