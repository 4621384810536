import React    from 'react'
import AboutSection from '../components/MainPage/AboutSection'
import AreaOfUse from '../components/MainPage/AreaOfUse'
import HomeSection from '../components/MainPage/HomeSection'
import ProductSection from '../components/MainPage/ProductSection'
import ProjectsSection from '../components/MainPage/ProjectsSection'
import NewsSection from '../components/MainPage/NewsSection'
import ContactSection from '../components/MainPage/ContactSection'
import ArticlesSection from '../components/MainPage/ArticlesSection'

 
const Home = () => {
   

    return (
        <div >  
           <HomeSection />
           <AboutSection />
           <ProductSection />
           <AreaOfUse/>
           <ProjectsSection   />
           <NewsSection />
           <ArticlesSection />
           <ContactSection />
        </div >
    )

    
}




export default Home
