import styled from 'styled-components'

export const News = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
`

export const FirstNews = styled.div`
    background: #000 url('img/background.png') no-repeat;
    background-position: center;
    background-size: cover;
    width:100%;


`


export const NewsScreenLayer = styled.div`
    background: rgba(0 , 0 , 0 , 0.6);
    width: 100%;
    height:100%;
    min-height:100vh;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

`
export const NewsContainer = styled.div`
    width:100%;
    max-width:1100px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap:wrap;
    @media screen and (max-width: 960px){
        width:90%;
    }
    
`
export const NewsContainerText = styled.div`
    width:100%;
    max-width:1100px;
    display:flex;

    flex-wrap:wrap;
    
    @media screen and (max-width: 960px){
        width:90%;
    }
`


export const NewsHeadTitle = styled.span`
    text-align:center;
    color:white;
    font-size:2rem;
    
    @media screen and (max-width: 960px){
        font-size: 1.3rem;
    }
`

export const NewsText = styled.p`
    margin:10px 0;
    font-size:1.1rem;
`

export const Li = styled.li`
    list-style-type:circle;
    margin-left:20px;
    font-weight:600;
`

export const LiText = styled.p`
    margin-left:20px;
    font-weight:500;
`

export const NewsAdditionalText = styled.div`
    
`

export const AdditionalText = styled.span`

`

export const NewsFullImage = styled.img`
    margin:20px;
    width:100%;
    @media screen and (max-width: 960px){
        margin:20px auto;
    width:90%;
    }
`
