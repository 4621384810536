import React from 'react'
import {ProductPage , ProductContainer , ProductHeader,SwipeContent,SlideText,SlideImage} from './ProjectEllements'

import {Models} from './Data'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation,Autoplay, Pagination, Scrollbar ,EffectCoverflow} from 'swiper';
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import "swiper/components/effect-coverflow/effect-coverflow.min.css"

import './ProjectStyle.css'

import Cookies from 'universal-cookie';
import {MainPageText} from '../../Library/LibraryMainPage'
const cookies = new Cookies();


const ListModel = Models.map((Models) =>
<SwiperSlide key={Models.image}>
    <SwipeContent to={Models.link}>
        <SlideImage src={Models.image} />
        <SlideText>{Models.text[cookies.get('language')]}</SlideText>
    </SwipeContent>
</SwiperSlide>
)


SwiperCore.use([EffectCoverflow,Autoplay,Navigation,Pagination, Scrollbar]);


const ProductSection = () => {
   

    return (
        <ProductPage id="project" >
            <ProductContainer>
                <ProductHeader>
                 {MainPageText[cookies.get('language')].project}
                </ProductHeader>

                <Swiper effect={'coverflow'} 
                        grabCursor={true} 
                        centeredSlides={true} 
                        slidesPerView={'auto'} 
                        loop={true} 
                        navigation
                        autoplay={{
                            "delay": 8000,
                            "disableOnInteraction": true
                          }} 
                        coverflowEffect={{"rotate": 30, "stretch": 0, "depth": 0,  "modifier": 1,  "slideShadows": false}} 
                        pagination={true} 
                        className="mySwiper">
                    
                    {ListModel}
                    
                </Swiper>
            </ProductContainer>
        </ProductPage>
    )
}





export default ProductSection
