import styled from 'styled-components'


export const AboutPage = styled.div`
    background:#EFEFEF;
    display:flex;
    justify-content:center;
    width:100%;
    
`

export const AboutContainer = styled.div`
    
    display:flex;
    flex-direction:column;
    text-align:center;
    justify-content:center;
    align-items:center;
    width:100%;
    max-width:1100px;
    padding-top:80px;

    @media screen and (max-width : 768px){
        padding:80px 20px 0;
    }
`

export const AboutHeader = styled.span`
    font-size:2rem;
    font-weight:700;
    margin-bottom:50px;
`

export const AboutText = styled.span`
    font-size:1.2rem;
    margin-bottom:50px;
    
`