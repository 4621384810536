import styled from 'styled-components'

export const TehnicalBlock = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    padding:20px;

`

export const TehnicalContainer = styled.div`
    width:100%;
    max-width:1100px;
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    
`

export const Image = styled.img`
    width:90%;
`