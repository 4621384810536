import React from 'react'
import {NewsPage , NewsContainer , NewsHeader , NewsContent, NewsFirstItem, FistImage, FirstText, NewsSecond, Second, SecondImg,SecondText} from './NewsEllements'



import news1 from '../../../img/news/news1.jpg'
import news2 from '../../../img/news/news2.png'
import news3 from '../../../img/news/news3.jpg'

import Cookies from 'universal-cookie';
import {MainPageText} from '../../Library/LibraryMainPage'
const cookies = new Cookies();

const NewsSection = () => {
    return (
        <NewsPage id="news">
            <NewsContainer>
                <NewsHeader>
                {MainPageText[cookies.get('language')].news}
                </NewsHeader>

                <NewsContent>
                    <NewsFirstItem to="/news1">
                        <FistImage src={news1} />
                        <FirstText >
                            {MainPageText[cookies.get('language')].news1}
                            </FirstText>
                    </NewsFirstItem>
                    <NewsSecond>
                        <Second  to="/news2">
                            <SecondImg src={news2} />
                            <SecondText>{MainPageText[cookies.get('language')].news2}
                               </SecondText>
                        </Second>

                        
                        <Second  to="/news3">
                              <SecondImg src={news3} />
                            <SecondText>{MainPageText[cookies.get('language')].news3}
                               </SecondText>
                        
                        </Second>
                    </NewsSecond>
                </NewsContent>
            </NewsContainer>
        </NewsPage>
    )
}

export default NewsSection
