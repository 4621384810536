import React from 'react'
import { ArtBlock, ArtContainer, FirstBlockArticles, FirstScreenLayer, TextArticlesBlock , Text, ArticleImg, TitleText , Li} from '../components/ArticlesPage/ArticlesEllements'

import Trigeneration1 from '../img/articles/trigeneration.jpg'


import Cookies from 'universal-cookie';
import {ArticleLibraryText} from '../components/Library/ArticleLibrary'
const cookies = new Cookies();

const Trigeneration = () => {
    
    window.scrollTo(0, 0);
    return (
        <>
            <FirstBlockArticles id="home">
                <FirstScreenLayer>
                <ArtContainer>
                    <ArtBlock>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].title}
                        
                    </ArtBlock>
                </ArtContainer>
                </FirstScreenLayer>
            </FirstBlockArticles> 
            <TextArticlesBlock>
                <ArtContainer>
                    <Text>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text1}
                        </Text>

                    <ArticleImg src={Trigeneration1} />

                    <TitleText>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text2}</TitleText>
                    <Text>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text3}</Text>

                    <TitleText>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text4}</TitleText>
                    <Text>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text5}</Text>

                    <TitleText>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text6}
                    </TitleText>
                    
                    <Li>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text7}</Li>
                    <Li>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text8}</Li>
                    <Li>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text9}</Li>

                    <TitleText>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text10}</TitleText>
                    <Li>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text11}</Li>
                    <Li>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text12}</Li>
                    <Li>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text13}</Li>
                    <Li>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text14}</Li>
                    <Li>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text15}</Li>
                    <Li>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text16}</Li>
                    <Text> 
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text17}</Text>
                
                    <TitleText>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text18}</TitleText>
                    <Li>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text19}</Li>
                    <Li>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text20}</Li>
                    <Li>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text21}</Li>
                    <Li>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text22}</Li>
                    

                    <TitleText>
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text23}</TitleText>
                    <Text>
                    
                    {ArticleLibraryText.Trigeneration[cookies.get('language')].text24}
                    </Text>
                </ArtContainer>
               
           </TextArticlesBlock>
        </>
    )
}

export default Trigeneration
