import React from 'react'
import { FirstNews, NewsContainer, NewsHeadTitle, NewsScreenLayer ,News, NewsText, NewsContainerText} from '../components/NewsPages/NewsEllements'

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"


import News3_1 from '../img/news/news3_1.jpg'
import News3_2 from '../img/news/news3_2.jpg'
import News3_3 from '../img/news/news3_3.jpg'
import News3_4 from '../img/news/news3_4.jpg'
import News3_5 from '../img/news/news3_5.jpg'
import News3_6 from '../img/news/news3_6.jpg'


  import Cookies from 'universal-cookie';
  import {NewsLibraryText} from '../components/Library/NewsLibrary'
  
import { SlideImage, SwipeContent } from '../components/Projects/ProjectsEllements';

import SwiperCore, {
    Pagination,Navigation
  } from 'swiper/core';
  SwiperCore.use([Pagination,Navigation]);
  
    const cookies = new Cookies();

const News3 = () => {
  
    window.scrollTo(0, 0);
    return (
        <>
            <News>
                
            <FirstNews>

                <NewsScreenLayer>
                     <NewsContainer>
                        <NewsHeadTitle>
                             {NewsLibraryText.news3[cookies.get('language')].title}
                        </NewsHeadTitle>
                    </NewsContainer>

                   
                </NewsScreenLayer> 
            </FirstNews> 
            

            <NewsContainerText>

            <NewsText>{NewsLibraryText.news3[cookies.get('language')].text1}</NewsText>

            <NewsText>{NewsLibraryText.news3[cookies.get('language')].text2}</NewsText>


            <NewsText>{NewsLibraryText.news3[cookies.get('language')].text3}</NewsText>

            <NewsText>{NewsLibraryText.news3[cookies.get('language')].text4}</NewsText>    

            <NewsText>{NewsLibraryText.news3[cookies.get('language')].text5}</NewsText>    

            <NewsText>{NewsLibraryText.news3[cookies.get('language')].text6}</NewsText>    
            </NewsContainerText>

            
            <Swiper effect={'coverflow'} 
                        grabCursor={true} 
                        centeredSlides={true} 
                        slidesPerView={'auto'} 
                        loop={true} 
                        navigation
                        autoplay={{
                            "delay": 4000,
                            "disableOnInteraction": true
                          }} 
                        coverflowEffect={{"rotate": 30, "stretch": 0, "depth": 0,  "modifier": 1,  "slideShadows": false}} 
                        pagination={true} 
                        className="mySwiper">
                    
                    <SwiperSlide >
                        <SwipeContent href={News3_1}  target="_blank">
                            <SlideImage src={News3_1} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide >
                        <SwipeContent  href={News3_2}  target="_blank">
                            <SlideImage src={News3_2} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={News3_3}  target="_blank">
                            <SlideImage src={News3_3} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={News3_4}  target="_blank">
                            <SlideImage src={News3_4} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={News3_5}  target="_blank">
                            <SlideImage src={News3_5} />
                        </SwipeContent>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwipeContent  href={News3_6}  target="_blank">
                            <SlideImage src={News3_6} />
                        </SwipeContent>
                    </SwiperSlide>
                    
                </Swiper>

           
            </News>
        </>
    )
}

export default News3
