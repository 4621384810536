import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { ArtBlock, ArtContainer, FirstBlockArticles, FirstScreenLayer, TextArticlesBlock , Text} from '../components/ArticlesPage/ArticlesEllements'




import Cookies from 'universal-cookie';
import {ArticleLibraryText} from '../components/Library/ArticleLibrary'

const cookies = new Cookies();


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  function createData(id, h1, h2, h3, h4) {
    return { id, h1, h2, h3, h4 };
  }
  
  const rows = [
    createData('1', 30,   30,	62,   94),
    createData('2', 76,   33.6, 122,  87.5 ),
    createData('3', 101,  35.7, 147,  87.8 ),
    createData('4', 125,  36.3, 177,  87.9),
    createData('5', 160,  36.8, 225,  88.6),
    createData('6', 178,  37,   249,  88.8 ),
    createData('7', 200,  37.4, 276,  89.1 ),
    createData('8', 330,  38.7, 358,  81 ),
    createData('9', 526,  39.4, 635,  87 ),
    createData('10',625,  39.8, 731,  86.4 ),
    createData('11',834,  39.9, 988,  87.2 ),
    createData('12',844,  43.1, 828,  85.4 ),
    createData('13',1063, 40.8, 1190, 86.4 ),
    createData('14',1131, 43.3, 1106, 85.7 ),
    createData('15',1415, 43.4, 1381, 85.7 ),
    createData('16',1801, 42.9, 2431, 86 ),
    createData('17',2002, 43.5, 1932, 86 ),
    createData('18',2390, 42.2, 2452, 85.5 ),
    createData('19',2679, 45.5, 2439, 87.8 ),
    createData('20',2994, 41.6, 3112, 84.9 ),
    createData('21',3349, 44.9, 3137, 87.4 ),
    createData('22',4029, 45.4, 3682, 86.5 ),
    createData('23',4400, 46.5, 4116, 90 ),
    createData('24*',9500, 48.7, 8056, 90 ),
  ];
  
  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });


const Powerengine = () => {
    const classes = useStyles();
    
    

    window.scrollTo(0, 0);
    return (
        <>
            <FirstBlockArticles>
                <FirstScreenLayer>
                <ArtContainer>
                    <ArtBlock>
                    {ArticleLibraryText.PowerOfRange[cookies.get('language')].title}
                    </ArtBlock>
                </ArtContainer>
                </FirstScreenLayer>
            </FirstBlockArticles> 
            <TextArticlesBlock>
                <ArtContainer>
               
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">№</StyledTableCell>
                                <StyledTableCell align="center">{ArticleLibraryText.PowerOfRange[cookies.get('language')].h1}</StyledTableCell>
                                <StyledTableCell align="center">{ArticleLibraryText.PowerOfRange[cookies.get('language')].h1}</StyledTableCell>
                                <StyledTableCell align="center">{ArticleLibraryText.PowerOfRange[cookies.get('language')].h1}</StyledTableCell>
                                <StyledTableCell align="center">{ArticleLibraryText.PowerOfRange[cookies.get('language')].h1}</StyledTableCell>
                                
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.id}>
                                <StyledTableCell  align="center" component="th" scope="row">
                                    {row.id}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.h1}</StyledTableCell>
                                <StyledTableCell align="center">{row.h2}</StyledTableCell>
                                <StyledTableCell align="center">{row.h3}</StyledTableCell>
                                <StyledTableCell align="center">{row.h4}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                

                    <Text>{ArticleLibraryText.PowerOfRange[cookies.get('language')].text}
                    </Text>
                </ArtContainer>
               
           </TextArticlesBlock>
        </>
    )
}

export default Powerengine
