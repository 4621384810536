import React  from 'react'
import FirstBlock from '../components/SeriaModel/FirstBlock'
import FourBlock from '../components/SeriaModel/FourBlock'
import SecondBlock from '../components/SeriaModel/SecondBlock'
import ThirdBlock from '../components/SeriaModel/ThirdBlock'

const model2 = () => {
    
    window.scrollTo(0, 0);
    return (
        <>  
            <FirstBlock ellement={2} />
            <SecondBlock ellement={2} />

            <ThirdBlock ellement={2} record={1} modif={true}/>
            <ThirdBlock ellement={2} record={2} modif={false}/>
            <ThirdBlock ellement={2} record={3} modif={true}/>
            <ThirdBlock ellement={2} record={4} modif={false}/>
            
            <FourBlock ellement={2}/>
        </>
    )
}

export default model2
